import { useHasBanner } from '@hooks/use-has-banner';
import { useWindowSize } from '@hooks/use-window-size';

import { useStore } from '@store/store';
import { cn } from '@utils/cn';
import { pxToRem } from '@utils/px-to-rem';
import { memo, useEffect, useMemo, useRef } from 'react';
import { shallow } from 'zustand/shallow';

type Props = {
  textOne: string;
  textTwo: string;
  url: string;
  className?: string;
  isDynamicNode: boolean;
};

export const HeaderCta = memo(({ isDynamicNode, textOne, textTwo, url, className }: Props) => {
  const selectedCard = useStore((state) => state.selectedCard, shallow);
  const ref = useRef<HTMLDivElement>(null);

  const amSelected = useMemo(
    () => (selectedCard === null ? null : isDynamicNode ? selectedCard.index === 0 : selectedCard.index !== 0),
    [isDynamicNode, selectedCard],
  );

  const refSelected = useRef(amSelected);

  useEffect(() => {
    refSelected.current = amSelected;
  }, [amSelected]);

  const { windowSize, isMobile } = useWindowSize();
  const { hasBannerAndIsMobile } = useHasBanner();

  const isTopFix = useMemo(() => windowSize.x < 439, [windowSize]);

  const heightValue = useMemo(() => (isMobile ? 140 : 300), [isMobile]);

  const heightoffset = useMemo(() => {
    if (isTopFix && !isDynamicNode) return -20;

    if (isMobile) {
      return 0;
    }
    return 38;
  }, [isMobile, isDynamicNode, isTopFix]);

  const data = ref.current?.getBoundingClientRect?.();

  return (
    <div
      ref={ref}
      className={cn(
        'absolute -top-[140px] left-0 z-20 transition-all duration-500 ease-out',
        className,
        amSelected === false && 'scale-[60%] opacity-0',
        amSelected && 'z-[100]',
        !isDynamicNode && '-top-[160px] min-[439px]:-top-[140px]',
      )}
      style={
        amSelected
          ? {
              top: pxToRem(
                isDynamicNode && isMobile && document.getElementById('layout')?.scrollTop === 0
                  ? -140
                  : (data?.top ?? 0) > heightValue
                    ? -1 * ((data?.top ?? 0) - heightoffset) + (hasBannerAndIsMobile ? 40 : 0)
                    : heightoffset - (data?.top ?? 0) + (hasBannerAndIsMobile ? 40 : 0),
              ),
              left: pxToRem(isMobile ? 0 : (windowSize.x - 630) / 2 - (data?.left ?? 0)),
            }
          : undefined
      }
    >
      <p className={cn('font-bold text-[38px] text-meldwhite', amSelected && 'drop-shadow-sm')}>{textOne}</p>
      <p className={cn('mt-2 text-base !leading-[20px] text-meldwhite', amSelected && 'drop-shadow-sm')}>{textTwo}</p>
      <a
        href={url}
        target="__blank"
        className="mt-[7px] inline-block cursor-pointer uppercase text-meldgray4 no-underline transition-all hover:text-meldgray4/80"
      >
        learn more
      </a>
    </div>
  );
});
