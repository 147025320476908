import { useStore } from '@store/store';
import { useQuery } from '@tanstack/react-query';
import { getSaleData } from '@utils/get-sale-data';

export const GET_SALE_DATA_QUERY_KEY = 'GET_SALE_DATA_QUERY_KEY';

export const useSaleData = () => {
  const saleEnded = useStore((state) => state.data.saleEnded);

  const { isLoading, data, isError, isRefetching, fetchStatus } = useQuery(
    [GET_SALE_DATA_QUERY_KEY],
    () => getSaleData(),
    {
      enabled: !saleEnded,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
    },
  );

  return {
    isLoading: (isLoading && fetchStatus !== 'idle') || (isRefetching && fetchStatus !== 'idle'),
    data,
    isError,
  };
};
