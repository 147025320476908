import { useStore } from '@store/store';
import { getCurrentRootTree } from '@utils/worker';
import { memo, useEffect, useMemo, useRef } from 'react';
import { ORIGINAL_ROOT_NODE } from 'src/contants/original-root-node';
import { shallow } from 'zustand/shallow';

const MIN_TIME_FETCH_ROOT_NODE_MILISECONDS = 30000;
const MIN_TIME_FETCH_ROOT_NODE_MILISECONDS_WINDOW_FOCUS = 30000 * 10;

/**
 * - Checks whether the root node we have stored in the app is different than the one we have stored
 * in the global state.
 * - Possibly refreshes the root node whenever the user clicks a card or comes back to the page.
 */
export const TrackRootNode = memo(() => {
  const lastTimeItFetchedRootNodeRef = useRef(Date.now());
  const selectedCard = useStore((state) => state.selectedCard, shallow);
  const saleEnded = useStore((state) => state.data.saleEnded);

  const rootNode = useStore((state) => state.data.rootNode);
  const setData = useStore((state) => state.setData);

  const mismatchingRootNode = useMemo(() => !!(rootNode && rootNode !== ORIGINAL_ROOT_NODE), [rootNode]);

  const saleStopped = useMemo(
    () => !!(rootNode && rootNode === '0x0000000000000000000000000000000000000000000000000000000000000000'),
    [rootNode],
  );

  // get root node on app launch
  useEffect(() => {
    getCurrentRootTree();
  }, []);

  // refetch root node every MIN_TIME_FETCH_ROOT_NODE_MILISECONDS at most whenever the user selects a given card
  useEffect(() => {
    if (
      !saleEnded &&
      selectedCard &&
      Date.now() - lastTimeItFetchedRootNodeRef.current >= MIN_TIME_FETCH_ROOT_NODE_MILISECONDS
    ) {
      getCurrentRootTree();
      lastTimeItFetchedRootNodeRef.current = Date.now();
    }
  }, [selectedCard, saleEnded]);

  // refetch root node when user comes back to the page if its been more than MIN_TIME_FETCH_ROOT_NODE_MILISECONDS_WINDOW_FOCUS
  useEffect(() => {
    const handleWindowFocus = () => {
      if (
        !saleEnded &&
        Date.now() - lastTimeItFetchedRootNodeRef.current >= MIN_TIME_FETCH_ROOT_NODE_MILISECONDS_WINDOW_FOCUS
      ) {
        getCurrentRootTree();
        lastTimeItFetchedRootNodeRef.current = Date.now();
      }
    };

    window.addEventListener('focus', handleWindowFocus);

    return () => window.removeEventListener('focus', handleWindowFocus);
  }, [saleEnded]);

  // update global state
  useEffect(() => {
    if (!saleEnded) {
      setData({ saleStopped });
      setData({ mismatchingRootNode });
    }
  }, [saleStopped, mismatchingRootNode, setData, saleEnded]);

  return null;
});
