import { Network, NetworksResponse } from '../../../api/meld-app/networks/networks.types';

export const parseNetworks = (networks: NetworksResponse) => {
  return networks.reduce<Record<string, Network>>((prev, curr) => {
    if (curr.defaultTokenSymbol.toLowerCase().includes('gmeld')) {
      const img = new Image();
      img.src = curr.darkIcon;
    }
    prev[curr.name] = {
      ...curr,
    };

    return prev;
  }, {});
};
