import { WalletTokensResponse } from '@api/meld-app/wallet-tokens/wallet-tokens.types';
import { formatUnits } from 'ethers/lib/utils';

/**
 * Merges the balances we receive from the BE with a given balance passed to the function
 *
 * @remarks
 * Currently used to ensure the MELD balance is correct when the user stakes meld
 *
 * @params
 * balances - response from calling '/wallet-tokens'
 * tokenId - id of the token to be updated
 * fullAmout - balance of said token in WEI
 */
export const mergeBalances = (balances: WalletTokensResponse, tokenAddress: string, fullAmount: string) =>
  balances.map((item) =>
    item.contract === tokenAddress
      ? {
          ...item,
          amount: fullAmount,
          usdTotalPrice: Number(formatUnits(fullAmount, item.decimals)) * +item.price,
        }
      : item,
  );
