import { isNotProdEnv } from '../../utils/environments-helper';

const DEV_CHAINS = [
  {
    id: 222000222,
    name: 'kanazawa',
    nativeCurrency: { decimals: 18, name: 'gMELD', symbol: 'gMELD' },
    rpcUrls: {
      public: { http: ['https://subnets.avax.network/meld/testnet/rpc'] },
      default: { http: ['https://subnets.avax.network/meld/testnet/rpc'] },
    },
    blockExplorers: { default: { name: '', url: 'https://testnet.meldscan.io' } },
  },
];

const PROD_CHAINS = [
  {
    id: 333000333,
    name: 'meld',
    network: 'meld',
    nativeCurrency: { decimals: 18, name: 'gMELD', symbol: 'gMELD' },
    rpcUrls: {
      public: { http: ['https://rpc-1.meld.com'] },
      default: { http: ['https://rpc-1.meld.com'] },
    },
    blockExplorers: { default: { name: '', url: 'https://meldscan.io' } },
  },
];

export const WAGMI_CHAINS = isNotProdEnv ? DEV_CHAINS : PROD_CHAINS;
