import { useEthersSigner } from './use-ethers-signer';
import { useQuery } from '@tanstack/react-query';
import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';
import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { useUnsupportedExtNetwork } from './use-unsupported-ext-network';
import { getPurchases } from '@utils/get-purchases';

const CACHE_KEY = (address: string) => `purchases_${address}`;

export const GET_PURCHASES = 'GET_PURCHASES';

export const usePurchases = () => {
  const wallet = useEthersSigner();
  const availableTokens = useStore((state) => state.availableTokens, shallow);
  const evmAddress = useStore((state) => state.evmData.evmAddress);
  const loadedUsersBalances = useStore((state) => state.data.loadedUsersBalances);

  const { wrongEvmNetwork } = useUnsupportedExtNetwork();

  const { isLoading, data, isError, isRefetching, fetchStatus } = useQuery(
    [GET_PURCHASES, evmAddress],
    async () => {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const { data: cachedData, blockNumber: cachedBlockNumber } = JSON.parse(
            localStorage.getItem(CACHE_KEY(evmAddress as string)) ?? 'null',
          ) ?? { data: [], blockNumber: 0 };

          const { data, blockNumber } = await getPurchases(
            evmAddress as string,
            availableTokens as AvailableToken[],
            cachedBlockNumber || undefined,
          );

          const newCachedData = data.concat(cachedData);
          localStorage.setItem(
            CACHE_KEY(evmAddress as string),
            JSON.stringify({ data: newCachedData, blockNumber: blockNumber + 1 }),
          );
          resolve(newCachedData);
        }, 2000);
      });
    },
    {
      enabled: !!wallet && !!availableTokens && !wrongEvmNetwork && !!evmAddress && loadedUsersBalances,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading: (isLoading && fetchStatus !== 'idle') || (isRefetching && fetchStatus !== 'idle'),
    data,
    isError,
  };
};
