import Menu from '@mui/material/Menu';
import { shortenAddress } from '../../utils/shorten-address';
import copyIcon from '../../assets/copy.svg';
import shareIcon from '../../assets/share.svg';
import disconnectIcon from '../../assets/disconnect.svg';
import closeIcon from '../../assets/close.svg';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { cn } from '@utils/cn';
import { useIsMobile } from '@hooks/use-is-mobile';
import { formatCryptoBalance } from '@utils/format-currency';
import { AnimatedEllipsis } from '@components/animated-ellipsis';
import { getBackgroundForCard } from '@utils/get-background-color-for-scroll';
import { Purchases } from '@typings/sale';
import Node from '../../assets/node.svg';
import NodeDynamic from '../../assets/node-dynamic.svg';
import { createPortal } from 'react-dom';

export type WalletMenuProps = {
  open: HTMLElement | null;
  handleClose: () => void;
  address?: string;
  onDisconnect: () => void;
  isConnected: boolean;
  data?: Purchases;
  walletExplorerUrl: string;
  isLoading: boolean;
};

export const WalletMenu = ({
  isLoading,
  open,
  handleClose,
  address,
  onDisconnect,
  data,
  walletExplorerUrl,
}: WalletMenuProps) => {
  const copyToClipboard = useCallback(() => navigator.clipboard.writeText(address as string), [address]);
  const isMobile = useIsMobile();
  const addressRef = useRef(address);

  useEffect(() => {
    if (address) addressRef.current = address;
  }, [address]);

  const content = useMemo(() => {
    return (
      <div>
        <div className={cn('pt-5', isMobile && 'pt-4', !data || (data.length === 0 && 'pr-0'))}>
          {/* Header */}
          <div className={cn('flex justify-between pl-5', data?.length && 'pr-0')}>
            <p className={cn('font-semibold text-[18px] text-meldwhite')}>
              {address || addressRef.current ? shortenAddress(address ?? (addressRef.current as string), 4, 5) : ''}
            </p>
            <div className="flex items-center gap-5 pr-5 [&>img]:cursor-pointer">
              <img onClick={copyToClipboard} src={copyIcon} className="hover:opacity-60" />
              <img
                onClick={() => window.open(walletExplorerUrl, '_blank')}
                src={shareIcon}
                className="hover:opacity-60"
              />
              {
                <img
                  onClick={() => {
                    handleClose();
                    onDisconnect();
                  }}
                  src={disconnectIcon}
                  className="mr-2 hover:opacity-60"
                />
              }
              <img
                onClick={handleClose}
                src={closeIcon}
                className={cn('relative top-[-3px] hover:opacity-60', isMobile && 'top-0')}
              />
            </div>
          </div>
          {
            <div>
              <p className="mb-2 pl-5 font-semibold text-[11px] uppercase tracking-[0.44px] text-meldwhite opacity-50">
                My Purchases
              </p>
              <div
                className={cn(
                  'flex h-[calc(100vh-280px)] flex-col overflow-y-auto pb-5 xm:h-[calc(100vh-400px)] md:max-h-[calc(100vh-600px)]',
                  isMobile && '!h-[calc(65vh_-_78px)]',
                  !data || (data?.length === 0 && 'mr-0 pr-0'),
                  isMobile && (!data || data.length === 0) && '!h-[calc(40v1_-_78px)]',
                )}
              >
                {isLoading ? (
                  <p className="mb-[48px] mt-[35px] flex flex-row items-center justify-center text-center font-semibold text-[16px] uppercase tracking-[0.44px] text-meldwhite">
                    Loading <AnimatedEllipsis className="relative -top-[2px] left-[2px]" />
                  </p>
                ) : data?.length ? (
                  data.map(({ timestamp, explorerUrl, symbol, amount, description }) => {
                    return (
                      <a
                        target="_blank"
                        href={explorerUrl}
                        key={timestamp}
                        className={cn('group flex justify-between py-[6px] pr-5 text-meldwhite no-underline')}
                      >
                        <div className={cn('flex items-center pl-5')}>
                          <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full drop-shadow-lg">
                            <img
                              src={description.toLowerCase().includes('dynamic') ? NodeDynamic : Node}
                              alt="MELD Node"
                            />
                          </div>
                          <div className="font-semibold">{description}</div>
                          <img
                            src={shareIcon}
                            className="relative -left-4 z-0 ml-2 h-4 w-4 opacity-0 transition-all duration-500 group-hover:left-0 group-hover:opacity-100"
                          />
                        </div>
                        <div className="font-semibold">
                          <div className="text-right text-xl">{formatCryptoBalance(amount, symbol, true)}</div>
                          <div className="text-right text-[11px]">
                            {dayjs(timestamp * 1000).format('MMM D, YYYY hh:mm')}
                          </div>
                        </div>
                      </a>
                    );
                  })
                ) : (
                  <p className="mb-[48px] mt-[35px] text-center font-semibold text-[11px] uppercase tracking-[0.44px] text-meldwhite">
                    Your purchases will appear here
                  </p>
                )}
              </div>
            </div>
          }
        </div>
      </div>
    );
  }, [address, copyToClipboard, data, handleClose, isMobile, onDisconnect, walletExplorerUrl, isLoading]);

  return (
    <>
      {createPortal(
        <div>
          <div
            onClick={handleClose}
            className={cn(
              'pointer-events-none fixed left-0 top-0 z-50 h-screen w-screen overflow-hidden bg-meldblack/65 opacity-0 transition-all duration-300',
              open && isMobile && 'pointer-events-auto z-[10000] opacity-100',
              !open && 'pointer-events-none opacity-0',
            )}
          />
          <div
            className={cn(
              'pointer-events-none fixed bottom-0 left-0 z-[51] h-[65vh] w-screen rounded-t-[27px] bg-meldred opacity-0',
              open && isMobile && 'bottomMenu pointer-events-auto z-[1000000] opacity-100',
              isMobile && (!data || data.length === 0) && '!h-[40vh]',
            )}
          >
            {content}
          </div>
        </div>,
        document.body
      )}
      <div>
        <Menu
          id="wallet-menu"
          slotProps={{
            root: {
              className: cn(isMobile && 'hidden pointer-events-none -z-10'),
            },
            paper: {
              className: cn(
                'fixed max-h-screen w-screen !max-w-none !right-0 !top-[24px] md:!right-[50px] md:!top-[50px] !left-auto md:w-[369px] bg-meldred text-meldwhite rounded-lg rounded-tr-none',
              ),
              style: { background: getBackgroundForCard((window.scrollY * 100) / document.body.offsetHeight) },
            },
          }}
          MenuListProps={{ className: 'p-0' }}
          open={!!open}
          anchorEl={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {content}
        </Menu>
      </div>
    </>
  );
};
