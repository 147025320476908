import { SUPPORTED_TOKENS } from 'src/contants/meld';
import { AvailableToken, AvailableTokenBE } from '../../../api/meld-app/available-tokens/available-tokens.types';
import { Network } from '../../../api/meld-app/networks/networks.types';

export const parseAvailableTokens = (availableTokens: AvailableTokenBE[], networks: Record<string, Network>) => {
  const tokens: AvailableToken[] = availableTokens
    .map((item) => {
      // preload icons
      if (SUPPORTED_TOKENS.includes(item.tokenId)) {
        const img = new Image();
        img.src = item.icon;
      }
      const chainName = networks[item.network]?.name;
      if (chainName) {
        const toReturn: AvailableToken = {
          ...item,
          networkIconLight: networks[item.network].lightIcon,
          networkIconDark: networks[item.network].darkIcon,
          color: item.color,
          tokenIcon: item.icon,
          chainType: networks[item.network].chainType,
          chainName,
          isNative: item.isNativeToken,
        };

        return toReturn;
      }
    })
    .filter((a) => a) as AvailableToken[];

  return tokens;
};
