import { WindowSize } from '@store/app-slice';
import { useEffect, useMemo, useState } from 'react';
import { useIsMobile } from './use-is-mobile';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Omit<WindowSize, 'scrollOffset'>>({
    x: window.innerWidth,
    y: window.innerHeight,
  });

  const _isMobile = useIsMobile();

  useEffect(() => {
    const handleWidth = () => {
      const { innerHeight, innerWidth } = window;
      setWindowSize({ x: innerWidth, y: innerHeight });
    };

    // do not ever add scroll event listeners, it will cause a lot of unnnecessary rerender in components
    if (!_isMobile) window.addEventListener('resize', handleWidth, { passive: false });

    handleWidth();

    return () => {
      if (!_isMobile) window.removeEventListener('resize', handleWidth);
    };
  }, [_isMobile]);

  const isMobile = useMemo(() => windowSize.x < 768, [windowSize]);

  return { windowSize, isMobile };
};
