import {
  GET_METAMASK_WALLET_TOKENS_QUERY_KEY,
  getMetamaskWalletTokensQuery,
} from '@api/meld-app/wallet-tokens/wallet-tokens-query';
import { WalletTokensResponse } from '@api/meld-app/wallet-tokens/wallet-tokens.types';
import { useEthersSigner } from '@hooks/use-ethers-signer';
import { useStore } from '@store/store';
import { useQuery } from '@tanstack/react-query';
import { WalletToken } from '@typings/wallet-asset.types';
import { ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { useEffect, useMemo } from 'react';
import { MELD_NETWORK, SUPPORTED_TOKENS } from 'src/contants/meld';
import { shallow } from 'zustand/shallow';

const formatAmounts = async (promise: Promise<WalletTokensResponse>) => {
  const tokens = await promise;
  return tokens.map((a) => ({ ...a, amount: formatUnits(a.amount, a.decimals) }));
};

export const useWalletTokens = () => {
  const evmAddress = useStore((state) => state.evmData.evmAddress);
  const setUserTokens = useStore((state) => state.setUserTokens);
  const setData = useStore((state) => state.setData);
  const availableTokens = useStore((state) => state.availableTokens, shallow);

  const evmSigner = useEthersSigner();

  const {
    isLoading: isLoadingEvm,
    fetchStatus: fetchStatusEvm,
    data: dataEvm,
    isRefetching: isRefetchingEvm,
  } = useQuery(
    [GET_METAMASK_WALLET_TOKENS_QUERY_KEY, evmAddress],
    () =>
      formatAmounts(getMetamaskWalletTokensQuery(evmAddress as string, evmSigner as ethers.providers.JsonRpcSigner)),
    {
      refetchOnWindowFocus: false,
      enabled: !!evmAddress && !!evmSigner,
      refetchOnMount: false,
    },
  );

  const userTokens = useMemo(() => {
    if (!availableTokens?.length) return [];
    const nativeToken = dataEvm?.find((a) => a.network === MELD_NETWORK && a.isNative);
    const nativeTokenAvailableToken = availableTokens.find((a) => a.network === MELD_NETWORK && a.isNative);

    const userTokens = SUPPORTED_TOKENS.map((a) => {
      const userToken = dataEvm?.find((b) => b.tokenId === a && b.slug);
      const availableToken = availableTokens?.find((c) => c.tokenId === a && c.slug);

      if (userToken) return userToken;
      return { ...(availableToken ?? { amount: '0' }), amount: '0' } as WalletToken;
    });

    return nativeToken
      ? [...userTokens, { ...nativeToken, hide: true }]
      : [...userTokens, { ...nativeTokenAvailableToken, amount: '0', hide: true }];
  }, [dataEvm, availableTokens]);

  useEffect(() => {
    setUserTokens(userTokens as Array<WalletToken>);
    if (dataEvm) {
      setTimeout(() => {
        setData({ loadedUsersBalances: true });
      }, 100);
    }
  }, [userTokens, setUserTokens, dataEvm, setData]);

  return {
    isLoadingEvm: isLoadingEvm && fetchStatusEvm !== 'idle',
    fetchStatusEvm,
    dataEvm,
    isRefetchingEvm,
    userTokens,
  };
};
