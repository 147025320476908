import { useCallback, useRef, useState } from 'react';
import logo from '../assets/logo-meld.svg';
import meld from '../assets/meld.svg';
import { animated, config, useSpring } from 'react-spring';
import { cn } from '@utils/cn';
import { useLoadedInitialBeData } from '@hooks/use-loaded-initial-be-data';
import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';

export const Logo = () => {
  const [loop, setLoop] = useState(false);
  const [textStyleActive, setTextStyleActive] = useState(false);
  const loopRef = useRef(false);
  const setSelectedCard = useStore((state) => state.setSelectedCard);
  const selectedCard = useStore((state) => state.selectedCard, shallow);

  const loadedInitialBeData = useLoadedInitialBeData();

  const logoStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    onRest: () => {
      setTextStyleActive(true);
    },
  });

  const textStyle = useSpring(
    textStyleActive
      ? {
          from: { opacity: 0 },
          to: { opacity: 1 },
          delay: 300,
          onChange: (e) => {
            if (e.value.opacity > 0.5 && !loopRef.current) {
              loopRef.current = true;
              setLoop(true);
            }
          },
          config: config.molasses,
        }
      : {},
  );

  const handleUnselect = useCallback(() => setSelectedCard(null), [setSelectedCard]);

  return (
    <div
      onClick={handleUnselect}
      className={cn(
        'flex select-none items-center gap-[10px]',
        selectedCard && 'cursor-pointer',
        // pulse if data has not loaded yet
        textStyleActive && !loop && !loadedInitialBeData && 'animate-pulse',
      )}
    >
      <animated.img
        style={logoStyle}
        src={logo}
        className="logo react w-12 drop-shadow-[0px_4px_30px_rgba(0,_0,_0,_0.25)] md:h-[59px] md:w-[59px]"
        alt="meld logo"
      />
      <animated.img style={logoStyle} src={meld} className="logo react w-16 md:w-auto" alt="meld logo" />
      <animated.div
        style={textStyle}
        className={'relative -top-[10.5px]  hidden opacity-0 min-[430px]:block md:-top-[15px] [&_p]:m-0'}
      >
        <span
          className={cn(
            'absolute top-[3px] whitespace-nowrap font-light text-base text-white opacity-100 md:top-[2px] md:text-[27px]',
          )}
        >
          NODE SALE
        </span>
        <div className="absolute top-[3px] flex flex-row md:top-[2px]">
          <span
            onAnimationIteration={() => {
              setLoop(false);
            }}
            className={cn(
              'whitespace-nowrap font-light text-base text-white  opacity-100 md:text-[27px]',
              loop && 'logoText',
            )}
          >
            <div>NODE SALE</div>
          </span>
        </div>
      </animated.div>
    </div>
  );
};
