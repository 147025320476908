import { useStore } from '@store/store';
import evmWalletUtils, { getErc20Balance } from '@utils/evm/evmWalletUtils';
import { BNDecimals } from '@utils/format-currency/big-number';
import { ethers } from 'ethers';

import { WalletTokensResponse } from './wallet-tokens.types';
import { axiosInstance } from '@api/axios';
import { MELD_TOKEN_ADDRESS } from 'src/contants/meld';
import { mergeBalances } from '@utils/merge-balances';
import { WalletToken } from '@typings/wallet-asset.types';
import { formatEther } from 'ethers/lib/utils';

const mergeBalancesData = async (query: () => Promise<Array<WalletToken>>, wallet: ethers.providers.JsonRpcSigner) => {
  const data = await query();
  try {
    const meldBalance = await getErc20Balance({
      wallet: wallet,
      contractAddress: MELD_TOKEN_ADDRESS,
      walletAddress: useStore.getState().evmData.evmAddress as string,
    });

    return mergeBalances(data, MELD_TOKEN_ADDRESS, meldBalance.toString());
  } catch (err) {
    console.error(err);
  }
  return data;
};

/**
 * Here we fetch the native token balances of the external EVM address because once an external EVM wallet
 * connects to our app, we register it with the BE but the BE doesn't have any native EVM token balances
 * until the very next block. This prevents us from showing missing balances, fetching the native EVM token
 * balance from chain directly.
 *
 * @param walletAddress string
 */
export const getMetamaskWalletTokensQuery = async (
  walletAddress: string,
  wallet: ethers.providers.JsonRpcSigner,
): Promise<WalletTokensResponse> => {
  const results = await Promise.all([
    (await axiosInstance.get<WalletTokensResponse>('/balance', { params: { walletAddress: walletAddress } })).data,
    evmWalletUtils.getNativeTokenBalances(walletAddress),
  ]);

  const newBalances = results[0].map((token) => {
    const newTokenBalance = results[1].find((newToken) => newToken.tokenSymbol === token.symbol && token.isNative);
    if (newTokenBalance) {
      return {
        ...token,
        amount: newTokenBalance.balance,
        fiatAmount: BNDecimals(formatEther(newTokenBalance.balance)).times(token.price).toString(),
      };
    }
    return token;
  });

  return mergeBalancesData(() => new Promise((resolve) => resolve(newBalances)), wallet);
};


export const GET_METAMASK_WALLET_TOKENS_QUERY_KEY = 'GET_METAMASK_WALLET_TOKENS_QUERY_KEY';
