import { getTokenIconByUrl } from '@utils/assets-helper';
import { BOX_SHADOW } from '../contants/style';
import { cn } from '../utils/cn';

type Props = {
  className?: string;
  imgSrc: string;
  boxShadow?: boolean;
  border?: boolean;
  mainWrapperClassName?: string;
  innerWrapperClassName?: string;
  borderColor?: string;
};

export const TokenIcon = ({
  imgSrc,
  boxShadow = true,
  border,
  innerWrapperClassName,
  mainWrapperClassName = 'h-[38px] w-[38px]',
  borderColor,
}: Props) => {
  return (
    <div
      className={cn(
        'flex items-center justify-center rounded-full bg-transparent transition-all duration-500 pointer-events-none',
        border && 'bg-meldgreen',
        mainWrapperClassName,
        borderColor,
      )}
    >
      <div
        className={cn(
          'h-[30px] w-[30px] rounded-full [&_img]:h-full [&_img]:w-full',
          boxShadow && BOX_SHADOW,
          innerWrapperClassName,
        )}
      >
        {getTokenIconByUrl(imgSrc)}
      </div>
    </div>
  );
};
