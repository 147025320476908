import { cn } from '@utils/cn';
import { memo, ReactNode, useEffect, useState } from 'react';

export const Cards = memo(({ isLoading, cards }: { isLoading: boolean; cards: Array<ReactNode> }) => {
  const [isLoadingInitially, setIsLoadingInitially] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingInitially(false);
    }
  }, [isLoading]);
  return (
    <div className="mt-[48px] w-full">
      <div
        className={cn(
          'flex w-full auto-rows-max grid-cols-[repeat(auto-fit,minmax(300px,1fr))] flex-col gap-x-[14px] gap-y-[20px] opacity-100 transition-all duration-500 md:grid md:gap-y-[57px]',
          isLoadingInitially && 'pointer-events-none opacity-0',
        )}
      >
        {cards.map((a) => a)}
      </div>
    </div>
  );
});
