import { INetworkNames, Networks, setEvmNetworks, setNetworkNames, setNetworks } from '@utils/networks/networks';

import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { StateSlice } from '@typings/state-creator.types';
import { setSbAssets, setSbAssetsByContract, setSbAssetsByTokenId, setSbAssetsObj } from '@utils/assets-helper';
import { WalletToken } from '@typings/wallet-asset.types';
import { ValueInputError } from '@typings/ValueInputError';
import { BigNumber } from 'ethers';
import { SelectedCard } from '@typings/sale';

type EVMData = {
  evmConnectedChainId?: number | undefined;
  evmRequiresApproval?: boolean;
  evmWalletName?: string;
  evmTxFeeData?: { maxFeePerGas: BigNumber; maxPriorityFeePerGas: BigNumber };
  notBroadcastedExternalEVMWalletAddress: string | null;
  evmAddress: string | null;
  evmAddressRegistered: boolean;
};

export type Data = {
  // this turns true before startedAt is defined
  // essentially means we fired the popup for the user to accept the tx but they haven't accepted yet
  initiatedPayment: boolean;
  // when user accepts MM tx
  acceptedPayment: boolean;
  approving: boolean;
  startedAt?: number;
  failed: boolean;
  explorerUrl?: string;
  transactionCost: string;
  notEnoughToken: boolean;
  completed: boolean;
  loadedUsersBalances: boolean;
  amountInFiat: number;
  rootNode: null | string;
  generatingWhitelistProof: number;
  whitelistProof: Array<string>;
  isWhitelisted: boolean;
  saleStopped: null | boolean;
  mismatchingRootNode: null | boolean;
  refreshingDataDueToAvailabilityError: boolean;
  saleEnded: boolean;
  acceptedTerms: boolean;
};

type InputData = {
  amount: string;
  fiatAmount: string;
  inputError: ValueInputError | null;
};

export type WindowSize = { x: number; y: number; scrollOffset: number };

export type appSliceType = {
  userTokens: WalletToken[];
  setUserTokens: (userTokens: WalletToken[]) => void;

  evmData: EVMData;
  setEvmData: (newEvmData: Partial<EVMData>) => void;

  data: Data;
  setData: (newData: Partial<Data>) => void;

  inputData: InputData;
  setInputData: (newInputData: Partial<InputData>) => void;

  numberFormatting: { decimalSeparator: string; thousandsSeparator: string };
  setNumberFormatting: (data: { decimalSeparator: string; thousandsSeparator: string }) => void;

  selectedWalletToken: undefined | WalletToken;
  setSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => void;

  updateSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => void;

  setAvailableTokens: (availableTokens: AvailableToken[]) => void;
  availableTokens: AvailableToken[] | null;

  setNetworks: (networks: Networks) => void;
  networks: Networks | null;

  selectedCard: SelectedCard | null;
  setSelectedCard: (selectedCard: SelectedCard | null) => void;

  cardWidth: number;
  setCardWidth: (cardWidth: number) => void;

  windowSize: WindowSize;
  setWindowSize: (windowSize: WindowSize) => void;
};

export const createAppSlice: StateSlice<appSliceType> = (set) => ({
  userTokens: [],
  setUserTokens: (userTokens: WalletToken[]) => set({ userTokens }, false, 'setUserTokens'),

  windowSize: { x: window.innerWidth, y: window.innerHeight, scrollOffset: 0 },
  setWindowSize: (windowSize: WindowSize) => set({ windowSize }, false, 'setWindowSize'),

  cardWidth: 0,
  setCardWidth: (cardWidth: number) => set({ cardWidth }, false, 'setCardWidth'),

  selectedCard: null,
  setSelectedCard: (selectedCard: SelectedCard | null) => set({ selectedCard }, false, 'setSelectedCard'),

  evmData: {
    notBroadcastedExternalEVMWalletAddress: null,
    evmAddress: null,
    evmAddressRegistered: false,
  },
  setEvmData: (newEvmData: Partial<EVMData>) =>
    set((oldState) => ({ ...oldState, evmData: { ...oldState.evmData, ...newEvmData } }), false, 'setEvmData'),

  numberFormatting: { decimalSeparator: '.', thousandsSeparator: ',' },
  setNumberFormatting: (numberFormatting: { decimalSeparator: string; thousandsSeparator: string }) =>
    set({ numberFormatting }, false, 'setNumberFormatting'),

  data: {
    initiatedPayment: false,
    acceptedPayment: false,
    approving: false,
    failed: false,
    transactionCost: '',
    notEnoughToken: false,
    completed: false,
    loadedUsersBalances: false,
    amountInFiat: 0,
    rootNode: null,
    generatingWhitelistProof: 0,
    isWhitelisted: false,
    whitelistProof: [],
    saleStopped: null,
    mismatchingRootNode: null,
    refreshingDataDueToAvailabilityError: false,
    saleEnded: false,
    acceptedTerms: false,
  },
  setData: (newBridgeData: Partial<Data>) =>
    set((oldState) => ({ ...oldState, data: { ...oldState.data, ...newBridgeData } }), false, 'setBridgeData'),

  inputData: { amount: '', fiatAmount: '', inputError: null },
  setInputData: (newInputData: Partial<InputData>) =>
    set(
      (oldState) => ({
        ...oldState,
        inputData: {
          ...oldState.inputData,
          ...newInputData,
        },
      }),
      false,
      'setInputData',
    ),

  selectedWalletToken: undefined,
  setSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => {
    set(
      (oldState) => ({
        ...oldState,
        selectedWalletToken,
        transactionCost: '',
        evmData: { ...oldState.evmData, evmRequiresApproval: false },
        data: {
          ...oldState.data,
          notEnoughToken: false,
          transactionCost: '',
        },
        inputData: {
          ...oldState.inputData,
        },
      }),
      false,
      'setSelectedWalletToken',
    );
  },

  updateSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => {
    set(
      (oldState) => ({
        ...oldState,
        selectedWalletToken,
      }),
      false,
      'updateSelectedWalletToken',
    );
  },

  availableTokens: null,
  setAvailableTokens: (newAvailableTokens: AvailableToken[]) => {
    setSbAssets(newAvailableTokens);
    setSbAssetsObj(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.name] = curr;
        return prev;
      }, {}),
    );
    setSbAssetsByTokenId(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.tokenId] = curr;
        return prev;
      }, {}),
    );
    setSbAssetsByContract(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.contract] = curr;
        return prev;
      }, {}),
    );
    set({ availableTokens: newAvailableTokens }, false, 'setAvailableTokens');
  },

  networks: null,

  setNetworks: (newNetworks: Networks) => {
    setNetworks(newNetworks);

    // we do this to be able to access correct network manually
    const updatedNetworkNames: INetworkNames = Object.keys(newNetworks).reduce((prev, curr) => {
      let key: keyof INetworkNames;
      switch (curr) {
        case 'preprod':
          key = 'cardano';
          break;
        case 'mumbai':
          key = 'ethereum';
          break;
        case 'fuji':
          key = 'avalanche';
          break;
        case 'kanazawa':
          key = 'meld';
          break;
        default:
          key = curr as keyof INetworkNames;
          break;
      }
      prev[key] = curr;
      return prev;
    }, {} as INetworkNames);

    setNetworkNames(updatedNetworkNames);
    setEvmNetworks(Object.values(newNetworks).filter((network) => network.chainType === 'evm'));
    set({ networks: newNetworks }, false, 'setNetworks');
  },
});
