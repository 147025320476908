import { useSpring, to } from '@react-spring/web';
import { RefObject } from 'react';
import { useGesture } from 'react-use-gesture';

export const use3d = (ref: RefObject<HTMLDivElement>) => {
  const [{ x, y, rotateX, rotateY, rotateZ, zoom, scale }, api] = useSpring(() => ({
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    scale: 1,
    zoom: 0,
    x: 0,
    y: 0,
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  useGesture(
    {
      onMove: ({ dragging }) => {
        if (y && x && !dragging) {
          api({
            scale: 1.02,
          });
        }
      },
      onHover: ({ hovering }) => !hovering && api({ rotateX: 0, rotateY: 0, scale: 1 }),
    },
    { domTarget: ref, eventOptions: { passive: false } },
  );

  return {
    transform: 'perspective(600px)',
    scale: to([scale, zoom], (s, z) => s + z),
    rotateX,
    rotateY,
    rotateZ,
  };
};
