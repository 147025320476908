import { useStore } from '@store/store';
import { WorkerEventTypes } from 'src/contants/worker';
import { storeWhitelistProofCache } from './whitelist-proof-cache';

export const worker = new Worker(new URL('../worker.ts', import.meta.url), { type: 'module' });

worker.onmessage = (event) => {
  const {
    data: { type, result, sentAddress },
  } = event;

  console.log('Message from worker: ', event.data);

  if (type === WorkerEventTypes.GET_CURRENT_ROOT_THREE) {
    useStore.getState().setData({ rootNode: result });
  } else if (type === WorkerEventTypes.GET_WHITELIST_PROOF) {
    const address = useStore.getState().evmData.evmAddress;
    // only update the global state if the address matches the one we were expecting as user may update the address while we are whitelisting

    storeWhitelistProofCache({ address: sentAddress as string, whitelist: result });
    if (address === sentAddress) {
      useStore.getState().setData({
        generatingWhitelistProof: useStore.getState().data.generatingWhitelistProof - 1,
        whitelistProof: result,
      });
    }
  }
};

export const getCurrentRootTree = () => worker.postMessage({ type: WorkerEventTypes.GET_CURRENT_ROOT_THREE });

export const getWhitelistProof = (address: string) => {
  worker.postMessage({ type: WorkerEventTypes.GET_WHITELIST_PROOF, address });
};
