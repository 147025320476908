export const MELD_TOKEN_ADDRESS =
  import.meta.env?.VITE_APP_ENV !== 'production'
    ? '0x22200025a5bc2c7da9c8ed6c051a58e12efa7501'
    : '0x333000333528b1e38884a5d1ef13615b0c17a301';

export const MELD_TOKEN_ID =
  import.meta.env?.VITE_APP_ENV == 'development' ? 26953 : import.meta.env?.VITE_APP_ENV == 'staging' ? 15193 : 78434;

export const MELD_CHAIN_ID = import.meta.env?.VITE_APP_ENV == 'development' ? 222000222 : 333000333;

export const MELD_NETWORK = import.meta.env?.VITE_APP_ENV == 'development' ? 'kanazawa' : 'meld';

export const MELD_DISCORD = 'https://discord.com/invite/onmeld';
export const MELD_TELEGRAM = 'https://t.me/OnMeld';

import { isNotProdEnv } from '../utils/environments-helper';

export const STATIC_NODES_URL =
  'https://docs.meld.com/meld-blockchain/zkbanking-network/node-sale-details#static-nodes';
export const DYNAMIC_NODES_URL =
  'https://docs.meld.com/meld-blockchain/zkbanking-network/node-sale-details#dynamic-nodes';
export const CONTRACTS =
  import.meta.env?.VITE_APP_ENV == 'development'
    ? {
        ZkNodesNFT: '0x4299E474D698a664f18746aEDe2bCdA34BEA9146',
        ZkNodesNFTMinter: '0x193582E97aD4128c813353b6110894227c745451',
        ZkNodesNFTMetadata: '0x51999a64A572527530461c94DA924970601bCE5C',
      }
    : {
        ZkNodesNFT: '0x333000bEC2bE4662f9b3bccd85eC5823CD7404ba',
        ZkNodesNFTMinter: '0x333000a6418a40B3489eB4ed6c96F7083A2Ee0BB',
        ZkNodesNFTMetadata: '0xd0E462Dda31488b1316B72D5a974Ce802eBAa5b2',
      };

export const SUPPORTED_TOKENS = isNotProdEnv
  ? ['kanazawa-0x000000a3b29aa88c2086a4893408e3df2abc80eb', 'kanazawa-0x111111abd9aef0413c2c8792803c68c6cbaa1ba2']
  : ['meld-0x333003370a882860ec176078a8939c9a3b4bbc05', 'meld-0x333002599c1bad8d3861baa959d0f9e2a4d57c06'];

export const VOLUMES_PER_TIER: Record<number, string> = {
  1: '450',
  2: '420',
  3: '400',
  4: '380',
  5: '360',
  6: '340',
  7: '330',
  8: '310',
  9: '300',
  10: '290',
  11: '280',
  12: '270',
  13: '260',
  14: '250',
  15: '240',
  16: '230',
  17: '230',
  18: '220',
  19: '210',
  20: '210',
  21: '200',
  22: '200',
  23: '200',
  24: '190',
  25: '190',
  26: '190',
  27: '190',
  28: '180',
  29: '180',
  30: '180',
};

export const SALE_OPENING_TIMESTAMP = 1727967600;
