import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { WAGMI_CHAINS } from './chains';

// 1. Get projectId at https://cloud.walletconnect.com
export const WC_PROJECT_ID = import.meta.env?.VITE_APP_WALLET_CONNECT_PROJECT_ID ?? '';

// 2. Create wagmiConfig
const metadata = {
  name: 'MELD Business',
  description: '',
  url: 'https://meld.com',
  icons: ['https://meld-assets.fra1.cdn.digitaloceanspaces.com/meld_logo_sm.png'],
};

export const WAGMI_CONFIG = defaultWagmiConfig({
  // @ts-expect-error 2322
  chains: WAGMI_CHAINS,
  projectId: WC_PROJECT_ID,
  metadata,
});
