import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { MUI_THEME } from './theme';
import { Layout } from './components/layout';
import { useInitialBEData } from '@hooks/use-initial-be-data/use-initial-be-data';
import { useWalletsRegisterAndSynced } from '@hooks/use-wallets-register-and-synced';
import { useWalletTokens } from '@hooks/use-wallet-tokens';
import { useStore } from '@store/store';
import { useOverrideWcColors } from '@hooks/use-override-wc-colors';
import { useEffect, useMemo } from 'react';
import { getDecimalSeparator } from '@utils/format-currency/get-decimals-separator';
import getUserLocale from 'get-user-locale';
import { Card } from '@components/card/index';
import { NodeAvailability } from '@typings/node-availability';
import { useNumberOfElementsPerRow } from '@hooks/use-number-of-elements-per-row';
import { HeaderCta } from '@components/header-cta';
import { DYNAMIC_NODES_URL, STATIC_NODES_URL, VOLUMES_PER_TIER } from './contants/meld';
import { useSaleData } from '@hooks/use-sale-data';
import '@utils/worker';
import { Cards } from '@components/cards';
import { useCalculateTxFee } from '@hooks/use-calculate-tx-fee';
import { useEthersSigner } from '@hooks/use-ethers-signer';
import { TrackRootNode } from '@components/track-root-node';
import { useOutdatedRoot } from '@hooks/use-outdated-root';
import { shallow } from 'zustand/shallow';
import { usePurchases } from '@hooks/use-purchases';
import { TrackTiers } from '@components/track-tiers';
import { cn } from '@utils/cn';
import { useIsMobile } from '@hooks/use-is-mobile';
import { BNDecimals } from '@utils/format-currency/big-number';
import Terms from '@components/terms';

const TOTAL_GAIN_YEAR = 250 * 48;

function App() {
  const amount = useStore((state) => state.inputData.amount);
  const selectedCard = useStore((state) => state.selectedCard, shallow);
  const evmRequiresApproval = useStore((state) => state.evmData.evmRequiresApproval);
  const approving = useStore((state) => state.data.approving);
  const initiatedPayment = useStore((state) => state.data.initiatedPayment);
  const whitelistProof = useStore((state) => state.data.whitelistProof, shallow);
  const evmAddress = useStore((state) => state.evmData.evmAddress);
  const isWhitelisted = useStore((state) => state.data.isWhitelisted);
  const saleEnded = useStore((state) => state.data.saleEnded);

  const setNumberFormatting = useStore((state) => state.setNumberFormatting);
  const setSelectedCard = useStore((state) => state.setSelectedCard);

  const wallet = useEthersSigner();

  const { data, isLoading } = useSaleData();
  const { isLoading: isLoadingFee } = useCalculateTxFee();
  const { saleStopped, mismatchingRootNode } = useOutdatedRoot();
  const isMobile = useIsMobile();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 400);
  }, []);

  useEffect(() => {
    const handler = () => {
      if (!isMobile) setSelectedCard(null);
    };

    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, [setSelectedCard, isMobile]);

  const numberOfElementsPerRow = useNumberOfElementsPerRow();

  useEffect(() => {
    const decimalSeparator = getDecimalSeparator(getUserLocale());
    setNumberFormatting({ decimalSeparator, thousandsSeparator: decimalSeparator === '.' ? ',' : '.' });
  }, [setNumberFormatting]);

  useOverrideWcColors();
  useInitialBEData();
  useWalletsRegisterAndSynced();
  useWalletTokens();
  usePurchases();

  const generatedCardsConsideringWidth = useMemo(() => {
    if (!data)
      return Array.from({ length: 31 }, (_, i: number) => (
        <Card
          index={i}
          key={i.toString()}
          tierName={''}
          price={'100'}
          isDynamicNode
          apy={'45'}
          total={'450'}
          acceptedCurrencies={['USDT', 'USDC']}
          totalNodes={0}
          availableNodes={0}
          purchaseMode={false}
          nodeAvailability={NodeAvailability.AVAILABLE}
          isLoadingFee
          amount=""
          approving
          saleDisabled
          initiatedPayment
          generatingWhitelistProof
          requiresWhitelisting
          isWhitelisted
          updatingData
          saleEnded
          tierMax={0}
        />
      ));
    const newCards = [];
    let counter = numberOfElementsPerRow;

    for (let i = 0; i < data.length; i++) {
      const tierData = data[i];
      const { supplyCap, totalMinted, requiresWhitelist, price, tierMax } = tierData;
      const availability =
        i === 0 && !tierMax
          ? NodeAvailability.SOLD_OUT
          : +tierData.supplyCap - +tierData.totalMinted <= 0
            ? NodeAvailability.SOLD_OUT
            : requiresWhitelist && !isWhitelisted
              ? NodeAvailability.WHITELIST
              : NodeAvailability.AVAILABLE;

      const tierName = tierData.dynamic ? 'Open Tier' : `Tier ${i}`;

      const tierApi = i === 0 ? 0 : BNDecimals(TOTAL_GAIN_YEAR / +price).toFixed(1);

      const card = (
        <Card
          tierMax={tierMax}
          index={i}
          key={i.toString()}
          tierName={tierName}
          price={price.toString()}
          isDynamicNode={i === 0}
          apy={i === 0 ? '10-700' : tierApi.toString()}
          total={i === 0 ? '120-1500' : VOLUMES_PER_TIER[i]}
          acceptedCurrencies={['USDT', 'USDC']}
          totalNodes={i === 0 ? +supplyCap : availability === NodeAvailability.SOLD_OUT ? +totalMinted : +supplyCap}
          availableNodes={
            i === 0
              ? +supplyCap - +totalMinted
              : availability === NodeAvailability.SOLD_OUT
                ? 0
                : +supplyCap - +totalMinted
          }
          purchaseMode={false} // ??
          nodeAvailability={availability}
          requiresWhitelisting={requiresWhitelist}
          /**
           * this is so we don't cause a re-render of all cards just because
           * some prop unselected cards don't care about changed
           */
          amount={i === selectedCard?.index ? amount : ''}
          isLoadingFee={i === selectedCard?.index ? isLoadingFee : false}
          requiresApproval={i === selectedCard?.index ? evmRequiresApproval : false}
          approving={i === selectedCard?.index ? approving : false}
          wallet={i === selectedCard?.index ? wallet : undefined}
          initiatedPayment={i === selectedCard?.index ? initiatedPayment : false}
          saleDisabled={
            i === selectedCard?.index
              ? saleStopped
                ? i !== 0
                : mismatchingRootNode && requiresWhitelist
                  ? true
                  : false
              : false
          }
          generatingWhitelistProof={
            i === selectedCard?.index
              ? isWhitelisted && requiresWhitelist && !!evmAddress && whitelistProof.length === 0
              : false
          }
          updatingData={i === selectedCard?.index ? isLoading : false}
          isWhitelisted={isWhitelisted}
          saleEnded={saleEnded}
        />
      );

      if (i && i % counter === 0) {
        counter += numberOfElementsPerRow - 1;
        newCards.push(<div />);
        newCards.push(
          <>
            {i == 0 && <p>Dynamic Nodes</p>}
            {card}
          </>,
        );
        continue;
      }
      newCards.push(
        <div className={cn('relative', i === 1 && 'mt-[180px] min-[439px]:mt-[160px] md:mt-0')}>
          {i == 0 && (
            <HeaderCta
              className="w-[320px]"
              textOne="Dynamic Nodes."
              textTwo="MELDs zkBanking dynamic nodes generate a yield based on network activity."
              url={DYNAMIC_NODES_URL}
              isDynamicNode
            />
          )}
          {i == 1 && (
            <HeaderCta
              className="w-full max-w-[500px] md:min-w-[480px]"
              textOne="Static Nodes."
              textTwo="With a fixed APY of 48% these MELD zkBanking nodes provide a fixed a predictable yield for three years."
              url={STATIC_NODES_URL}
              isDynamicNode={false}
            />
          )}
          {card}
        </div>,
      );
    }

    return newCards;
  }, [
    numberOfElementsPerRow,
    data,
    isWhitelisted,
    amount,
    selectedCard,
    isLoadingFee,
    evmRequiresApproval,
    approving,
    wallet,
    initiatedPayment,
    mismatchingRootNode,
    saleStopped,
    whitelistProof,
    evmAddress,
    isLoading,
    saleEnded,
  ]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MUI_THEME}>
        <TrackRootNode />
        <TrackTiers />
        <Terms />
        <Layout>
          <Cards isLoading={isLoading} cards={generatedCardsConsideringWidth} />
          <div id="scrollbar-measure" />
        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
