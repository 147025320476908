import { useEffect, useMemo, useState } from 'react';

const checkHasScrollbar = () =>
  (document.getElementById('scrollbar-measure')?.offsetWidth ?? 0) -
  (document.getElementById('scrollbar-measure')?.clientWidth ?? 0);

export const useNumberOfElementsPerRow = () => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [scrollbarWidth, setScrollbarWidth] = useState(checkHasScrollbar());

  useEffect(() => {
    const updateViewportDimensions = () => {
      setScrollbarWidth(checkHasScrollbar());
      setViewportWidth(window.innerWidth);
    };

    setScrollbarWidth(checkHasScrollbar());

    window.addEventListener('resize', updateViewportDimensions);

    return () => {
      window.removeEventListener('resize', updateViewportDimensions);
    };
  }, []);

  const numberOfElementsPerRow = useMemo(() => {
    if (viewportWidth >= 660 && viewportWidth < 1028 + scrollbarWidth) return 2;
    if (viewportWidth >= 1028 + scrollbarWidth && viewportWidth < 1342 + scrollbarWidth) return 3;
    if (viewportWidth >= 1342 + scrollbarWidth && viewportWidth < 1656 + scrollbarWidth) return 4;
    if (viewportWidth >= 1656 + scrollbarWidth && viewportWidth < 1970 + scrollbarWidth) return 5;
    if (viewportWidth >= 1970 + scrollbarWidth && viewportWidth < 2284 + scrollbarWidth) return 6;
    if (viewportWidth >= 2284 + scrollbarWidth && viewportWidth < 2598 + scrollbarWidth) return 7;
    if (viewportWidth >= 2598 + scrollbarWidth) return 8;
  }, [viewportWidth, scrollbarWidth]);

  return numberOfElementsPerRow ?? 2;
};
