import { useNativeToken } from '@hooks/use-native-token';
import { useOutdatedRoot } from '@hooks/use-outdated-root';
import { useUnsupportedExtNetwork } from '@hooks/use-unsupported-ext-network';
import { useStore } from '@store/store';
import { NodeAvailability } from '@typings/node-availability';
import { ValueInputError } from '@typings/ValueInputError';
import { cn } from '@utils/cn';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { MELD_DISCORD, MELD_TELEGRAM } from 'src/contants/meld';
import { useSwitchChain } from 'wagmi';
import { shallow } from 'zustand/shallow';

const ErrorTypes = {
  NOT_ENOUGH_TOKEN: 'NOT_ENOUGH_TOKEN',
  NOT_ENOUGH_NATIVE_TOKEN: 'NOT_ENOUGH_NATIVE_TOKEN',
  WRONG_EVM_NETWORK: 'WRONG_EVM_NETWORK',
  NOT_WHITELISTED: 'NOT_WHITELISTED',
  SALE_STOPPED_WHITELISTED: 'SALE_STOPPED_WHITELISTED',
  AVAILABLE_AMOUNT_CHANGED: 'AVAILABLE_AMOUNT_CHANGED',
} as const;

type WrongEvmNetworkType = { type: typeof ErrorTypes.WRONG_EVM_NETWORK; walletNameToUse: string };
type NotEnoughNativeTokenType = { type: typeof ErrorTypes.NOT_ENOUGH_NATIVE_TOKEN; nftBridgeSelected: boolean };
type NotEnoughTokenType = { type: typeof ErrorTypes.NOT_ENOUGH_TOKEN; symbol: string };
type NotWhitelisted = { type: typeof ErrorTypes.NOT_WHITELISTED };
type SaleStoppedWhitelisted = { type: typeof ErrorTypes.SALE_STOPPED_WHITELISTED };
type AvailableAmountChanged = { type: typeof ErrorTypes.AVAILABLE_AMOUNT_CHANGED };

type Error = { showingError: boolean } & (
  | NotEnoughNativeTokenType
  | WrongEvmNetworkType
  | NotEnoughTokenType
  | NotWhitelisted
  | AvailableAmountChanged
  | SaleStoppedWhitelisted
);

const MeldSocial = () => (
  <>
    {' '}
    <a href={MELD_DISCORD} target="_blank" className="hover:text-meldwhite/60">
      discord
    </a>{' '}
    or{' '}
    <a href={MELD_TELEGRAM} target="_blank" className="hover:text-meldwhite/60">
      telegram
    </a>{' '}
  </>
);

const classNameWhitelistMessages = cn(
  'bg-meldblack p-1 text-left font-semibold text-[11px] !leading-[15px] tracking-[0.44px] text-meldwhite/80',
  '[&>a]:text-meldwhite [&>a]:no-underline [&>a]:transition-colors',
);

export const UnsupportedWalletMessage = memo(({ enabled, canShow }: { enabled: boolean; canShow: boolean }) => {
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const notEnoughToken = useStore((state) => state.data.notEnoughToken);
  const isWhitelisted = useStore((state) => state.data.isWhitelisted);
  const selectedCard = useStore((state) => state.selectedCard, shallow);
  const ref = useRef<HTMLDivElement>(null);

  const inputError = useStore((state) => state.inputData.inputError);
  const enteredAmount = useStore((state) => state.inputData.amount);
  const { evmWalletName } = useStore((state) => state.evmData, shallow);

  const nativeToken = useNativeToken({ network: selectedWalletToken?.network });
  const { wrongEvmNetwork, correctChainId } = useUnsupportedExtNetwork();
  const { saleStopped } = useOutdatedRoot();

  const notEnoughNativeToken = useMemo(() => inputError === ValueInputError.NOT_ENOUGH_NATIVE_TOKEN, [inputError]);

  const [hasError, setHasError] = useState<Error | null>(null);
  const { switchChain } = useSwitchChain();

  const error = useMemo(() => {
    if (inputError === ValueInputError.AVAILABLE_AMOUNT_CHANGED) return { type: ErrorTypes.AVAILABLE_AMOUNT_CHANGED };
    if (saleStopped && selectedCard?.requiresWhitelisting && selectedCard.availability !== NodeAvailability.SOLD_OUT)
      return { type: ErrorTypes.SALE_STOPPED_WHITELISTED };
    if (!isWhitelisted && selectedCard?.requiresWhitelisting && selectedCard.availability !== NodeAvailability.SOLD_OUT)
      return { type: ErrorTypes.NOT_WHITELISTED };
    if (wrongEvmNetwork && nativeToken && enteredAmount)
      return { type: ErrorTypes.WRONG_EVM_NETWORK, walletNameToUse: evmWalletName as string };
    if (+enteredAmount && notEnoughNativeToken) return { type: ErrorTypes.NOT_ENOUGH_NATIVE_TOKEN };
    if (+enteredAmount && notEnoughToken)
      return { type: ErrorTypes.NOT_ENOUGH_TOKEN, symbol: selectedWalletToken?.symbol };
    return null;
  }, [
    enteredAmount,
    nativeToken,
    wrongEvmNetwork,
    notEnoughToken,
    notEnoughNativeToken,
    evmWalletName,
    isWhitelisted,
    selectedCard,
    selectedWalletToken?.symbol,
    inputError,
    saleStopped,
  ]);

  const dimensionData = ref.current?.getBoundingClientRect();

  const selectedTokenRef = useRef(selectedWalletToken);

  useEffect(() => {
    selectedTokenRef.current = selectedWalletToken;
  }, [selectedWalletToken]);

  const wrongNetworkState =
    canShow && enabled && hasError?.showingError
      ? { opacity: 1, bottom: -1 * (dimensionData?.height ?? 0) - 20, config: config.slow }
      : { opacity: 0, bottom: -1 * (dimensionData?.height ?? 0) - 40, config: config.slow };

  useEffect(() => {
    setHasError((oldError) => {
      const showingError = !!error;
      setTimeout(() => {
        setHasError((oldState) => ({ ...oldState, showingError }) as Error);
      }, 50);
      return { ...(error ?? oldError), showingError: false } as Error;
    });
    /**
     * error above varies with amount which means this useEffect runs whenever the
     * amount is updated and thats no good. so we have type as dependecy
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.type]);

  const wrongNetworkSpring = useSpring(wrongNetworkState);

  const getErrorMessage = () => {
    switch (hasError?.type) {
      case ErrorTypes.AVAILABLE_AMOUNT_CHANGED:
        return <>The available amount for this tier changed, please try again.</>;
      case ErrorTypes.SALE_STOPPED_WHITELISTED:
        return (
          <p className={classNameWhitelistMessages}>
            The static tiers sales are currently closed. when they open for sale whitelisted wallets have first right to
            mint, if there are nodes still available then we will have an open sale. We will notify the MELD community
            of the node sale at least 7 days prior to the sale. If you don’t have a whitelist, please join the MELD
            <MeldSocial />
            to learn more about how to get whitelisted.
          </p>
        );
      case ErrorTypes.NOT_WHITELISTED:
        return (
          <p className={classNameWhitelistMessages}>
            This wallet does not have a whitelist allocation. Currently this tier is only available for whitelisted
            wallets. Your whitelist may be associated to another wallet. If you don't have a whitelist, please join the
            MELD
            <MeldSocial />
            to learn more about how to get whitelisted.
          </p>
        );
      case ErrorTypes.WRONG_EVM_NETWORK:
        return (
          <>
            Please{' '}
            <span
              onClick={() => switchChain && switchChain({ chainId: correctChainId as number })}
              className={cn(
                hasError?.type === ErrorTypes.WRONG_EVM_NETWORK &&
                  "relative inline-block cursor-pointer after:block after:h-[1px] after:w-full after:bg-meldred after:content-['_']",
              )}
            >
              switch to the correct network
            </span>{' '}
            in {hasError.walletNameToUse} to proceed.
          </>
        );
      case ErrorTypes.NOT_ENOUGH_TOKEN:
        return `Not enough ${hasError.symbol ?? ''} to cover the payment cost.`;
      case ErrorTypes.NOT_ENOUGH_NATIVE_TOKEN:
        return `Not enough ${nativeToken?.symbol ?? ''} to cover the transaction fee.`;

      default:
        return '';
    }
  };

  return (
    <animated.div
      ref={ref}
      style={wrongNetworkSpring}
      className={cn(
        'absolute left-0 z-10 m-0 mx-auto box-border flex w-full items-center justify-center rounded-lg bg-meldblack px-4 py-3 pt-[14px] text-center opacity-0',
        "before:absolute before:-top-[6px] before:z-0 before:block before:h-5 before:w-5 before:rotate-45 before:bg-meldblack before:content-['_']",
        (!canShow || !enabled || !hasError?.showingError) && 'pointer-events-none',
        hasError?.type !== ErrorTypes.NOT_WHITELISTED && 'py-5',
      )}
    >
      <p className={cn('font-semibold text-[12px] leading-[18px] text-meldred')}>{getErrorMessage()}</p>
    </animated.div>
  );
});
