import { ReactNode, useEffect, useState } from 'react';
import { Header } from './header';
import { animated, useSpring } from 'react-spring';
import { useLoadedInitialBeData } from '@hooks/use-loaded-initial-be-data';
import { cn } from '@utils/cn';
import isMobile from 'is-mobile';
import { WhitelistMaintenanceBanner } from './whitelist-maintenance-banner';
import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';
import { useWindowSize } from '@hooks/use-window-size';
import { useHasBanner } from '@hooks/use-has-banner';

type Props = {
  children: ReactNode;
};

const _isMobile = isMobile();

const MIN_HEIGHT_DESKTOP = 1100;
const MIN_HEIGHT_MOBILE = 1200;

export const Layout = ({ children }: Props) => {
  const loadedInitialBeData = useLoadedInitialBeData();
  const selectedCard = useStore((state) => state.selectedCard, shallow);

  const [extendHeight, setExtendHeight] = useState(0);
  const { isMobile } = useWindowSize();
  const { hasBannerAndIsMobile } = useHasBanner();

  const style = useSpring(
    loadedInitialBeData ? { from: { opacity: 0 }, to: { opacity: 1 }, delay: _isMobile ? 1000 : 2500 } : {},
  );

  useEffect(() => {
    setTimeout(() => {
      if (selectedCard) {
        const screenHeight = window.innerHeight;
        const minHeight = isMobile ? MIN_HEIGHT_MOBILE : MIN_HEIGHT_DESKTOP;
        if (screenHeight < minHeight) setExtendHeight(screenHeight + minHeight - screenHeight);
      } else setExtendHeight(0);
    }, 500);
  }, [selectedCard, isMobile]);

  return (
    <div
      id="layout"
      className={cn(
        'mx-auto box-border max-w-[2600px] overflow-auto p-6 transition-all duration-500 md:p-[50px]',
        selectedCard && 'overflow-hidden',
      )}
      style={{ height: extendHeight ? extendHeight : '100vh' }}
    >
      <WhitelistMaintenanceBanner />
      <div className={cn('h-0 transition-all duration-500', hasBannerAndIsMobile && 'h-[50px]')} />
      <Header />
      <animated.div
        style={style}
        className={cn(
          'mx-auto mt-[150px] flex w-full flex-col items-center px-0 opacity-0 md:mt-[160px]  md:w-auto min-[1370px]:px-0',
        )}
      >
        {children}
      </animated.div>
    </div>
  );
};
