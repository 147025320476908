import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import { MetricEvents } from '@typings/metric-events';
import { isNotProdEnv } from './environments-helper';

export const initMetricCollection = () => {
  Sentry.init({
    environment: 'production',
    dsn: 'https://69997a543c7781d3160a3d7abbe4a69c@o4505550632976384.ingest.us.sentry.io/4507934706302976',
    attachStacktrace: true,
  });
  posthog.init(import.meta.env.VITE_APP_METRICS_KEY as string, {
    api_host: 'https://eu.i.posthog.com',
    autocapture: false,
  });
};

export const capture = <T>(event: MetricEvents, metadata?: T) => {
  if (isNotProdEnv) return;
  try {
    metadata ? posthog.capture(event, metadata) : posthog.capture(event);
  } catch (err) {
    console.error('posthog error');
  }
};

export const captureError = <T>(error: T) => {
  console.error(error);
  !isNotProdEnv && Sentry.captureException(error);
};
