import { useStore } from '@store/store';
import { useAccount, useAccountEffect } from 'wagmi';

import {
  getWalletRegistrationQuery,
  postWalletRegistrationQuery,
} from '@api/meld-app/wallet-registration/wallet-registration-query';
import { useWalletRegisterAndSynced } from './use-wallet-register-and-synced';
import { SupportedWalletBE } from '@typings/wallet-asset.types';
import { useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { useIsPaying } from './use-is-paying';
import { capture } from '@utils/metrics';
import { MetricEvents } from '@typings/metric-events';
import { ADDRESSES } from 'src/contants/addresses';
import { getWhitelistProofCache } from '@utils/whitelist-proof-cache';
import { getWhitelistProof } from '@utils/worker';

export const useWalletsRegisterAndSynced = () => {
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const setInputData = useStore((state) => state.setInputData);
  const setData = useStore((state) => state.setData);
  const isPaying = useIsPaying();

  const notBroadcastedExternalEVMWalletAddress = useStore(
    (state) => state.evmData.notBroadcastedExternalEVMWalletAddress,
    shallow,
  );

  const setEvmData = useStore((state) => state.setEvmData);

  const { connector } = useAccount();

  const resetEvmRelatedData = useCallback(() => {
    if (isPaying) {
      location.reload();
    }
    if (selectedWalletToken?.chainType === NetworkChainType.EVM) {
      setInputData({ inputError: null });
      setEvmData({ evmRequiresApproval: false });
      setData({ transactionCost: '', notEnoughToken: false });
    }
  }, [setInputData, setEvmData, setData, isPaying, selectedWalletToken?.chainType]);

  useAccountEffect({
    onConnect(data) {
      if (!data.isReconnected) {
        capture(MetricEvents.UserConnectsEVMWallet, {
          wallet: data?.connector?.name ?? 'No-name',
        });
      }

      setEvmData({
        evmWalletName: data.connector.name,
        evmConnectedChainId: data.chainId,
        notBroadcastedExternalEVMWalletAddress: data.address,
        evmAddress: null,
        evmRequiresApproval: undefined,
      });
    },
    onDisconnect() {
      resetEvmRelatedData();
      setEvmData({ evmWalletName: '', evmConnectedChainId: undefined, evmAddress: null });
      setData({ whitelistProof: [] });
    },
  });

  useEffect(() => {
    const handleConnectorUpdate = (
      data: {
        accounts?: readonly `0x${string}`[] | undefined;
        chainId?: number | undefined;
      } & {
        uid: string;
      },
    ) => {
      if (data.accounts) {
        if (notBroadcastedExternalEVMWalletAddress !== data.accounts[0]) {
          setData({ loadedUsersBalances: false });
          setEvmData({ notBroadcastedExternalEVMWalletAddress: data.accounts[0] as string });
          resetEvmRelatedData();
        }
      } else if (data.chainId) {
        setEvmData({ evmConnectedChainId: data.chainId });
      }
    };

    if (connector) {
      connector?.emitter?.on('change', handleConnectorUpdate);
    }

    return () => connector?.emitter?.off('change', handleConnectorUpdate);
  }, [
    connector,
    notBroadcastedExternalEVMWalletAddress,
    setEvmData,
    selectedWalletToken,
    setInputData,
    resetEvmRelatedData,
    setData,
  ]);

  useWalletRegisterAndSynced({
    notBroadcastedAddress: notBroadcastedExternalEVMWalletAddress as string,
    postWalletRegistrationQuery,
    getWalletRegistrationQuery,
    setAddressRegistered: (flag) => setEvmData({ evmAddressRegistered: flag }),
    setWalletAddress: (address) => {
      const whitelistProof = getWhitelistProofCache(address);
      const isWhitelisted = ADDRESSES.includes(address);
      if (isWhitelisted && !whitelistProof) {
        getWhitelistProof(address);
      }
      setData({ loadedUsersBalances: false, isWhitelisted, whitelistProof: whitelistProof ?? [] });
      setEvmData({ evmAddress: address });
    },
    walletSyncedQuery: (address: string) =>
      new Promise((resolve) => resolve({ ready: true, address: address.toLowerCase() })),

    walletName: 'MetaMask', // TODO - refactor later if we support more web3 connectors
    walletType: SupportedWalletBE.EVM,
  });
};
