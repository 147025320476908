import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useSaleData } from '@hooks/use-sale-data';
import { cn } from '@utils/cn';
import { useWindowSize } from '@hooks/use-window-size';
import { useStore } from '@store/store';
import { capture } from '@utils/metrics';
import { MetricEvents } from '@typings/metric-events';

const LOCAL_STORAGE_KEY_TERMS = 'ACCEPTED_TERMS';
const TERMS_URL =
  'https://meld-labs.notion.site/MELD-zkBanking-Node-Sale-Terms-Conditions-c41fa994c36042828668100dc33211aa?pvs=74';

export default function Terms() {
  const { isLoading } = useSaleData();
  const [open, setOpen] = React.useState(false);
  const setData = useStore((state) => state.setData);

  const acceptedTerms = React.useMemo(() => JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_TERMS) ?? 'false'), []);

  React.useEffect(() => {
    if (acceptedTerms) {
      setData({ acceptedTerms: true });
    }
  }, [acceptedTerms, setData]);

  const { isMobile } = useWindowSize();

  React.useEffect(() => {
    if (!isLoading && !acceptedTerms) {
      setTimeout(
        () => {
          setOpen(true);
        },
        isMobile ? 1000 : 2500,
      );
    }
  }, [isLoading, acceptedTerms, isMobile]);

  if (acceptedTerms) return null;

  return (
    <>
      <div
        className={cn(
          'pointer-events-none fixed left-0 top-0 z-[1000] h-screen w-screen bg-black/60 opacity-0 backdrop-blur-sm transition-all duration-[0.75s]',
          open && 'opacity-100',
        )}
      />
      <div className={cn('pointer-events-none fixed z-[10001] h-screen w-screen ', open && 'pointer-events-auto')}>
        <div
          className={cn(
            'relative left-[50%] top-[60%] max-w-[82vw] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-lg bg-meldblack p-4 opacity-0 shadow-lg transition-all delay-0 duration-[0.35s] ease-out md:max-w-[600px] md:p-6',
            open && 'top-[50%] opacity-100 delay-[0.35s]',
          )}
        >
          <DialogTitle className="relative -left-[8px] font-semibold text-meldwhite">Terms And Conditions</DialogTitle>
          <DialogContent className="py-[22px]">
            <p className="font-base mb-2 !leading-[20px] tracking-[0.42px] text-meldwhite">
              Welcome to the MELD zkBanking node sale. Please review the node sale{' '}
              <a
                href={TERMS_URL}
                target="_blank"
                className="text-meldwhite/80 no-underline transition-all hover:text-meldwhite/60"
              >
                Terms & Conditions
              </a>{' '}
              that govern use of this site, its content and the purchase of virtual nodes in the node sale.
            </p>
            <p className="font-base !leading-[20px] tracking-[0.42px] text-meldwhite">
              Please note that the use of this site constitutes your acceptance of these{' '}
              <a
                href={TERMS_URL}
                target="_blank"
                className="text-meldwhite/80 no-underline transition-all hover:text-meldwhite/60"
              >
                Terms & Conditions
              </a>{' '}
              and your agreement to be bound by them.
            </p>
          </DialogContent>
          <DialogActions className="my-2 flex justify-center pr-4">
            <div className="flex gap-2">
              <div
                onClick={() => {
                  window.open('http://meld.com', '_self');
                  capture(MetricEvents.UserRejectsTerms);
                }}
                className="flex w-[120px] cursor-pointer items-center justify-center rounded-md bg-meldred py-3 font-semibold text-[12px] uppercase text-meldwhite transition-all hover:bg-meldred/80"
              >
                Reject
              </div>
              <div
                onClick={() => {
                  localStorage.setItem(LOCAL_STORAGE_KEY_TERMS, 'true');
                  setOpen(false);
                  setData({ acceptedTerms: true });
                  capture(MetricEvents.UserAcceptsTerms);
                }}
                autoFocus
                className="flex w-[120px] cursor-pointer items-center justify-center rounded-md bg-meldgreen py-3 font-semibold text-[12px] uppercase text-meldblack outline-none transition-all  hover:bg-meldgreen/80"
              >
                Accept
              </div>
            </div>
          </DialogActions>
        </div>
      </div>
    </>
  );
}
