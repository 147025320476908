import { Selector } from './selector';
import { cn } from '../../utils/cn.ts';
import { NumericFormat } from 'react-number-format';
import { memo, ReactNode, useMemo } from 'react';
import { formatCurrency } from '@utils/format-currency/format-currency.util.ts';

const Label = ({ text, secondElement = null }: { text: ReactNode; secondElement?: ReactNode }) => (
  <div className="flex items-center ">
    <p className="mb-2 font-semibold text-[11px] uppercase tracking-[0.44px] text-white/50">{text}</p>
    {secondElement}
  </div>
);

export const TokenSelector = memo(
  ({
    decimalSeparator,
    thousandsSeparator,
    price,
    amount,
    setAmount,
    tierMax,
  }: {
    decimalSeparator: string;
    thousandsSeparator: string;
    price: number;
    amount: string;
    setAmount: (amount: string) => void;
    tierMax: number;
  }) => {
    const content = useMemo(
      () => (
        <>
          <div>
            <Label
              text={'Quantity'}
              secondElement={
                <div
                  onClick={() => setAmount(tierMax.toString())}
                  className="mb-2 ml-1 cursor-pointer font-semibold text-[11px] uppercase tracking-[0.44px] text-white/40"
                >
                  (Max: {tierMax})
                </div>
              }
            />
            <div className="relative box-border flex h-[59px] w-full	 grow items-center overflow-hidden rounded-lg bg-white px-4 font-semibold text-xl text-black/60 outline-none">
              <div className="absolute right-5 top-1 flex h-full flex-col items-center justify-around font-bold text-[23px] text-meldblack">
                <p
                  className="relative cursor-pointer select-none !leading-[1px] transition-all hover:scale-110"
                  onClick={() => setAmount(+amount + 1 > tierMax ? amount : (+amount + 1).toString())}
                >
                  +
                </p>
                <p
                  className="relative -top-1 cursor-pointer select-none !leading-[1px] transition-all hover:scale-110"
                  onClick={() => setAmount(+amount - 1 <= 0 ? '' : (+amount - 1).toString())}
                >
                  -
                </p>
              </div>
              <NumericFormat
                isAllowed={(values) => {
                  const { formattedValue, value } = values;
                  const allowed = formattedValue === '' || +value <= tierMax;
                  if (!allowed) {
                    setAmount(tierMax.toString());
                    return false;
                  }
                  return allowed;
                }}
                value={amount}
                decimalScale={0}
                onValueChange={(values) => {
                  setAmount(values.value);
                }}
                placeholder="0"
                allowNegative={false}
                thousandSeparator={thousandsSeparator}
                decimalSeparator={decimalSeparator}
                max={tierMax}
                className={cn(
                  'h-[59px] w-full border-0 py-0 pr-9 font-semibold text-xl outline-none placeholder:text-meldblack/60',
                )}
              />
            </div>
          </div>{' '}
          <div>
            <Label text="Total" />
            <div
              className={cn(
                'relative box-border flex h-[59px] w-full	grow items-center rounded-lg bg-white px-4 font-semibold text-xl text-black/60 text-meldblack outline-none',
                amount === '' && 'text-meldblack/60',
              )}
            >
              {formatCurrency(price * +amount)}
            </div>
          </div>
        </>
      ),
      [amount, decimalSeparator, price, setAmount, thousandsSeparator, tierMax],
    );

    return (
      <>
        <div className={cn('mt-[54px] flex w-full flex-col gap-3 min-[600px]:grid min-[600px]:grid-cols-3')}>
          <div className="relative">
            <Label text="Currency" />
            <Selector />
          </div>
          <div className="grid grid-cols-2 gap-3 min-[600px]:hidden">{content}</div>
          {content}
        </div>
      </>
    );
  },
);
