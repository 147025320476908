import { ethers } from 'ethers';
import evmContractUtils from './evm/evmContractUtils';

// import MELDZkNodesNFTMetadata from '../abi/MELDZkNodesNFTMetadata.json';
import MELDZkNodesNFTMinter from '../abi/MELDZkNodesNFTMinter.json';
// import MELDZkNodesNFT from '../abi/MELDZkNodesNFT.json';
import { Sale, SaleBlockchain } from '@typings/sale';
import { WAGMI_CHAINS } from '@components/wagmi-provider/chains';
import { CONTRACTS } from 'src/contants/meld';
import { useStore } from '@store/store';

const provider = new ethers.providers.JsonRpcProvider(WAGMI_CHAINS[0].rpcUrls.public.http[0]);
const minterNftContract = await evmContractUtils.connect(provider, CONTRACTS.ZkNodesNFTMinter, MELDZkNodesNFTMinter);
// const nodesNftContract = await evmContractUtils.connect(provider, CONTRACTS.ZkNodesNFTMinter, MELDZkNodesNFT);

let fetchedOnce = false;

export const getSaleData = async (): Promise<Array<Sale>> => {
  return new Promise((resolve) => {
    setTimeout(
      async () => {
        const [allTiers, totalSupply, totalPurchased] = await Promise.all([
          minterNftContract.getAllTiers(),
          minterNftContract.totalSupplyCap(),
          minterNftContract.totalZkNodesPurchased(),
        ]);

        const diff = +totalSupply.toString() - +totalPurchased.toString();

        if (!diff) {
          // flag to the app the sale has ended
          useStore.getState().setData({ saleEnded: true });
        }

        const processedTiers = (allTiers as Array<SaleBlockchain>)
          .map((a, index: number) => {
            const min = Math.min(diff, +a.supplyCap.toString() - +a.totalZkNodesPurchased.toString());
            const tierMax = index === 0 ? +totalSupply - +totalPurchased : min;

            return {
              price: a.price.toString(),
              supplyCap: a.supplyCap.toString(),
              totalMinted: a.totalZkNodesPurchased.toString(),
              requiresWhitelist: a.requiresWhitelist,
              disabled: a.disabled,
              dynamic: index === 0,
              tierMax: tierMax,
            };
          })
          .filter((a) => !a.disabled) as unknown as Array<Sale>;

        processedTiers[0].supplyCap = totalSupply.toString();
        fetchedOnce = true;
        resolve(processedTiers);
      },
      fetchedOnce && useStore.getState().selectedCard ? 2500 : 0,
    );
  });
};

// TODO - fix this to consider the thing above
// not being used for now, we're refetching all data
export const getTierData = async (tier: number) => {
  const data = await minterNftContract.getTier(tier);
  return {
    price: data.price.toString(),
    supplyCap: data.supplyCap.toString(),
    totalMinted: data.totalZkNodesPurchased.toString(),
    requiresWhitelist: data.requiresWhitelist,
    disabled: data.disabled,
    dynamic: tier === 0,
  };
};
