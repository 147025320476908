import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import './locales';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@api/query-client.ts';
import { Web3ModalProvider } from '@components/wagmi-provider/WagmiProvider.tsx';
import { initMetricCollection } from '@utils/metrics.ts';
import { isNotProdEnv } from '@utils/environments-helper.ts';

if (!isNotProdEnv && !location.host.includes('localhost') && !location.host.includes('local-app'))
  initMetricCollection();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Web3ModalProvider>
        <App />
      </Web3ModalProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
