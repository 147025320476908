import { useStore } from '@store/store';
import { useQuery } from '@tanstack/react-query';
import { shallow } from 'zustand/shallow';
import { useEthersSigner } from './use-ethers-signer';
import { useNativeToken } from './use-native-token';
import { CALCULATE_FEE_QUERY_KEY } from 'src/contants/calculate-fee';
import { useUnsupportedExtNetwork } from '@hooks/use-unsupported-ext-network';
import { captureError } from '@utils/metrics';
import { ValueInputError } from '@typings/ValueInputError';

import { providers } from 'ethers';
import { payEvm } from '@utils/pay';
import { useOutdatedRoot } from './use-outdated-root';
import { queryClient } from '@api/query-client';
import { GET_SALE_DATA_QUERY_KEY, useSaleData } from './use-sale-data';

export const useCalculateTxFee = () => {
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const { amount, inputError, fiatAmount } = useStore((state) => state.inputData, shallow);
  const { evmAddress } = useStore((state) => state.evmData, shallow);
  const { loadedUsersBalances, isWhitelisted, saleEnded } = useStore((state) => state.data, shallow);

  const setData = useStore((state) => state.setData);
  const setEvmData = useStore((state) => state.setEvmData);
  const setInputData = useStore((state) => state.setInputData);
  const selectedCard = useStore((state) => state.selectedCard, shallow);
  const whitelistProof = useStore((state) => state.data.whitelistProof, shallow);

  const evmWallet = useEthersSigner();
  const { saleStopped, mismatchingRootNode } = useOutdatedRoot();
  const { wrongEvmNetwork } = useUnsupportedExtNetwork();
  const { isLoading: isLoadingSaleData } = useSaleData();

  const nativeToken = useNativeToken({
    network: selectedWalletToken?.network,
  });

  const { isLoading, fetchStatus, isRefetching } = useQuery(
    [CALCULATE_FEE_QUERY_KEY, selectedWalletToken?.tokenId, evmAddress, amount, wrongEvmNetwork, isLoadingSaleData],
    async () => {
      try {
        if (+fiatAmount > +(selectedWalletToken?.amount ?? 0)) {
          setData({ notEnoughToken: true });
          return;
        }

        if (+(nativeToken?.amount ?? 0) === 0) {
          setInputData({
            inputError: ValueInputError.NOT_ENOUGH_NATIVE_TOKEN,
          });
          return;
        }

        if (saleStopped && selectedCard?.index !== 0) return;

        if (selectedCard?.requiresWhitelisting && !whitelistProof.length) return;

        setData({ notEnoughToken: false });

        const { cost, requiresApproval } = await payEvm({
          wallet: evmWallet as providers.JsonRpcSigner,
          estimateCost: true,
        });

        if (selectedCard === null) return;

        setEvmData({ evmRequiresApproval: requiresApproval });
        setData({ approving: false, transactionCost: '' });

        // if native token amount < tx fee
        const isOverSendableAmount = Number(cost) > +(nativeToken?.amount ?? 0);
        isOverSendableAmount && setInputData({ inputError: ValueInputError.NOT_ENOUGH_NATIVE_TOKEN });

        setData({
          transactionCost: cost,
        });
        return cost;
      } catch (err) {
        if (
          (err as Error)?.message.includes('TOTAL_SUPPLY_CAP_REACHED') ||
          (err as Error)?.message.includes('TIER_SUPPLY_CAP_REACHED')
        ) {
          queryClient.invalidateQueries([GET_SALE_DATA_QUERY_KEY]);
          setInputData({ inputError: ValueInputError.AVAILABLE_AMOUNT_CHANGED, amount: '', fiatAmount: '' });
          setTimeout(() => {
            setInputData({ inputError: null });
          }, 5000);
        } else {
          captureError(err);
          if (!inputError) {
            setInputData({
              inputError: ValueInputError.NOT_ENOUGH_NATIVE_TOKEN,
            });
          }
        }
      }

      return null;
    },
    {
      refetchOnWindowFocus: false,
      enabled:
        +amount > 0 &&
        loadedUsersBalances &&
        !!evmAddress &&
        !wrongEvmNetwork &&
        !!selectedCard &&
        (selectedCard.requiresWhitelisting ? isWhitelisted : true) &&
        (saleStopped && selectedCard.requiresWhitelisting ? false : true) &&
        (selectedCard.requiresWhitelisting && mismatchingRootNode ? false : true) &&
        (selectedCard.requiresWhitelisting && whitelistProof.length === 0 ? false : true) &&
        !isLoadingSaleData &&
        !saleEnded,
      refetchOnMount: false,
      staleTime: 0,
      cacheTime: 0,
    },
  );

  return {
    isLoading: (isLoading && fetchStatus !== 'idle') || (isRefetching && fetchStatus !== 'idle'),
  };
};
