import { ORIGINAL_ROOT_NODE } from 'src/contants/original-root-node';

export const getWhitelistProofCache = (address: string) => {
  const existingWhitelist = JSON.parse(localStorage.getItem(`white_list_${address}_${ORIGINAL_ROOT_NODE}`) ?? 'null');
  return existingWhitelist;
};

export const storeWhitelistProofCache = ({
  address,
  whitelist,
}: {
  address: string;
  whitelist: Array<string>;
}) => {
  localStorage.setItem(`white_list_${address}_${ORIGINAL_ROOT_NODE}`, JSON.stringify(whitelist));
};
