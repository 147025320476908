import { useStore } from '@store/store';
import { BigNumber, ethers, providers } from 'ethers';
import { formatEther, parseUnits } from 'ethers/lib/utils';
import { approveErc20, estimateApproveErc20, hasAllowanceErc20 } from './evm/evmTransactionUtils';
import evmContractUtils from './evm/evmContractUtils';
import { CONTRACTS } from 'src/contants/meld';
import MELDZkNodesNFTMinter from '../abi/MELDZkNodesNFTMinter.json';

export const payEvm = async ({
  wallet,
  onCompleteApproval,
  onAcceptedPayment,
  estimateCost,
}: {
  wallet: providers.JsonRpcSigner;
  onCompleteApproval?: () => void;
  onAcceptedPayment?: () => void;
  estimateCost: boolean;
}) => {
  const {
    inputData: { fiatAmount, amount },
    data: { whitelistProof },
    selectedWalletToken,
    evmData: { evmTxFeeData },
    setEvmData,
    selectedCard,
  } = useStore.getState();

  // if calculating the cost then we generate the fee data, else we expect it to be passed to the function as param
  const feeData = estimateCost
    ? await wallet.getFeeData()
    : {
        maxFeePerGas: evmTxFeeData?.maxFeePerGas,
        maxPriorityFeePerGas: evmTxFeeData?.maxPriorityFeePerGas,
      };

  const amountWei = parseUnits(fiatAmount, selectedWalletToken?.decimals ?? 18).toString();

  let cost = BigNumber.from(0);

  const needsAllowance = !(await hasAllowanceErc20({
    signer: wallet,
    spender: CONTRACTS.ZkNodesNFTMinter,
    value: amountWei,
    tokenAddress: selectedWalletToken?.contract ?? '',
  }));

  // approve erc20 transfer
  if (onCompleteApproval) {
    const approveTx = await approveErc20({
      signer: wallet,
      spender: CONTRACTS.ZkNodesNFTMinter,
      value: ethers.constants.MaxUint256.toString(),
      tokenAddress: selectedWalletToken?.contract ?? '',
      ...feeData,
    });

    if (approveTx) {
      onCompleteApproval();
      return { approved: true, requiresApproval: false };
    }
  } else {
    // check if user has allowance
    const approvalCost = needsAllowance
      ? await estimateApproveErc20({
          signer: wallet,
          spender: CONTRACTS.ZkNodesNFTMinter,
          value: amountWei, //ethers.constants.MaxUint256.toString(),
          tokenAddress: selectedWalletToken?.contract ?? '',
        })
      : null;
    // needs to approve, calculate costs
    if (needsAllowance && approvalCost) {
      setEvmData({
        evmTxFeeData: {
          maxFeePerGas: feeData.maxFeePerGas ?? BigNumber.from(0),
          maxPriorityFeePerGas: feeData.maxPriorityFeePerGas ?? BigNumber.from(0),
        },
      });
      const transactionCost = formatEther(approvalCost.mul(feeData.maxFeePerGas as BigNumber));

      // return costs
      return {
        requiresApproval: true,
        cost: transactionCost,
      };
    }
  }

  // user has approval
  const contract = await evmContractUtils.connect(wallet, CONTRACTS.ZkNodesNFTMinter, MELDZkNodesNFTMinter);

  if (estimateCost) {
    cost = await contract.estimateGas['purchaseZkNodes'](
      selectedCard?.index,
      Number(amount),
      selectedWalletToken?.contract,
      selectedCard?.requiresWhitelisting ? whitelistProof : [],
    );
  } else {
    // generate tx
    const tx = await contract['purchaseZkNodes'](
      selectedCard?.index,
      Number(amount),
      selectedWalletToken?.contract,
      selectedCard?.requiresWhitelisting ? whitelistProof : [],
    );
    onAcceptedPayment?.();
    await tx.wait(1);
    return { hash: tx.hash, requiresApproval: false };
  }

  const transactionCost = formatEther(cost.mul(feeData.maxFeePerGas as BigNumber));

  setEvmData({
    evmTxFeeData: {
      maxFeePerGas: feeData.maxFeePerGas ?? BigNumber.from(0),
      maxPriorityFeePerGas: feeData.maxPriorityFeePerGas ?? BigNumber.from(0),
    },
  });

  return { cost: transactionCost, requiresApproval: false };
};
