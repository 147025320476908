import { queryClient } from '@api/query-client';
import { GET_SALE_DATA_QUERY_KEY } from '@hooks/use-sale-data';
import { useStore } from '@store/store';
import { memo, useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

const MIN_TIME_FETCH_TIER_MILISECONDS = 120000;
const MIN_TIME_FETCH_TIERS_MILISECONDS_WINDOW_FOCUS = 30000 * 10;

// mutates the query data for when we fetch info related to a specific tier
/*
const mutateDataForSpecificTier = async (tier: number) => {
  const tierData = await getTierData(tier);
  queryClient.setQueryData([GET_SALE_DATA_QUERY_KEY], (data: Array<Sale> | undefined) => {
    return data?.map((a, i) => (tier === i ? (i === 0 ? { ...tierData, supplyCap: a.supplyCap } : tierData) : a));
  });
};*/

/**
 * - Possibly refreshes the tier(s) whenever the user clicks a card or comes back to the page.
 *
 * @see useSaleData() used in App.tsx - the query responsible for fetching all tiers initially
 */
export const TrackTiers = memo(() => {
  const lastTimeItFetchedTiersRef = useRef(Date.now());
  const selectedCard = useStore((state) => state.selectedCard, shallow);
  const saleEnded = useStore((state) => state.data.saleEnded);

  // refetch root node every MIN_TIME_FETCH_TIER_MILISECONDS at most whenever the user selects a given card
  // this was updated to fetch all data every 30 seconds assuming the user clicks on any given card - review later how to optimise
  useEffect(() => {
    if (
      !saleEnded &&
      selectedCard &&
      Date.now() - lastTimeItFetchedTiersRef.current >= MIN_TIME_FETCH_TIER_MILISECONDS
    ) {
      //mutateDataForSpecificTier(selectedCard.index);
      //fetchedTiersData[selectedCard.index] = Date.now();

      // temporary fix to deal with complexity
      queryClient.invalidateQueries([GET_SALE_DATA_QUERY_KEY]);
      lastTimeItFetchedTiersRef.current = Date.now();

      /**
       * updating this one as well... so that it only really triggers when the user comes back to the
       * page after more than MIN_TIME_FETCH_TIERS_MILISECONDS_WINDOW_FOCUS
       */
      lastTimeItFetchedTiersRef.current = Date.now();
    }
  }, [selectedCard, saleEnded]);

  // refetch all tiers when user comes back to the page if its been more than MIN_TIME_FETCH_TIERS_MILISECONDS_WINDOW_FOCUS
  useEffect(() => {
    const handleWindowFocus = () => {
      if (
        !saleEnded &&
        Date.now() - lastTimeItFetchedTiersRef.current >= MIN_TIME_FETCH_TIERS_MILISECONDS_WINDOW_FOCUS
      ) {
        queryClient.invalidateQueries([GET_SALE_DATA_QUERY_KEY]);
        lastTimeItFetchedTiersRef.current = Date.now();
      }
    };

    window.addEventListener('focus', handleWindowFocus);

    return () => window.removeEventListener('focus', handleWindowFocus);
  }, [saleEnded]);

  return null;
});
