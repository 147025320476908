import { useIsMobile } from '@hooks/use-is-mobile';
import { WindowSize } from '@store/app-slice';
import { useStore } from '@store/store';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

export const useDimensions = ({ index }: { index: number }) => {
  const selectedCard = useStore((state) => state.selectedCard, shallow);

  const containerRef = useRef<HTMLDivElement>(null);
  const dimensionsRef = useRef<Omit<DOMRect, 'toJSON'>>();
  const isMobile = useIsMobile();
  const [windowSize, setWindowSize] = useState<WindowSize>({
    x: window.innerWidth,
    y: window.innerHeight,
    scrollOffset: 0,
  });

  const amSelected = useMemo(() => selectedCard?.index === index, [selectedCard, index]);

  const refAmSelected = useRef(amSelected);
  const refSelectedCard = useRef(selectedCard);

  useEffect(() => {
    refSelectedCard.current = selectedCard;
  }, [selectedCard]);

  useEffect(() => {
    refAmSelected.current = amSelected;
  }, [amSelected]);

  const handleDimensions = useCallback(() => {
    if (refAmSelected.current) return;
    const { scrollY, innerHeight, innerWidth } = window;
    setWindowSize({ x: innerWidth, y: innerHeight, scrollOffset: scrollY });
    const data = containerRef.current?.getBoundingClientRect?.();
    if (data) {
      dimensionsRef.current = data;
    }
    return data;
  }, []);

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('resize', handleDimensions, { passive: false });
      window.addEventListener('wheel', handleDimensions, { passive: false });
      window.addEventListener('scroll', handleDimensions, { passive: false });
    }

    handleDimensions();

    return () => {
      if (!isMobile) {
        window.removeEventListener('resize', handleDimensions);
        window.removeEventListener('wheel', handleDimensions);
        window.addEventListener('scroll', handleDimensions);
      }
    };
  }, [setWindowSize, handleDimensions, isMobile]);

  return {
    windowSize,
    selectedCard,
    containerRef,
    amSelected,
    handleDimensions,
    dimensionsRef: dimensionsRef as { current: DOMRect },
  };
};
