import { Button } from '@mui/material';
import MeldLogoWhite from '../../assets/meld-logo-white.svg';
import Node from '../../assets/node.svg';
import NodeDynamic from '../../assets/node-dynamic.svg';

import { cn } from '@utils/cn';
import { memo, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { NodeAvailability } from '@typings/node-availability';
import { useStore } from '@store/store';
import { TokenSelector } from '../token-selector';
import { shallow } from 'zustand/shallow';
import { useDimensions } from './use-dimensions';
import { TokenIcon } from '@components/token-icon';
import { networks } from '@utils/networks/networks';
import { MELD_NETWORK } from 'src/contants/meld';
import shareIcon from '../../assets/share.svg';
import { queryClient } from '@api/query-client';
import { GET_SALE_DATA_QUERY_KEY } from '@hooks/use-sale-data';
import { Sale, SelectedCard } from '@typings/sale';
import { payEvm } from '@utils/pay';
import { ethers } from 'ethers';
import { convertEVMHashToLink } from '@utils/transaction-link.util';
import { formatCurrency } from '@utils/format-currency';
import { CALCULATE_FEE_QUERY_KEY } from 'src/contants/calculate-fee';
import { UnsupportedWalletMessage } from '@components/unsupported-wallet-message';
import { ValueInputError } from '@typings/ValueInputError';
import { GET_METAMASK_WALLET_TOKENS_QUERY_KEY } from '@api/meld-app/wallet-tokens/wallet-tokens-query';
import { GET_PURCHASES } from '@hooks/use-purchases';
import { use3d } from './use-3d';
import { animated, Interpolation, SpringValue } from 'react-spring';
import { useWindowSize } from '@hooks/use-window-size';
import { pxToRem } from '@utils/px-to-rem';
import { capture, captureError } from '@utils/metrics';
import { useHasBanner } from '@hooks/use-has-banner';
import { MetricEvents } from '@typings/metric-events';

type Props = {
  index: number;
  tierName: string;
  price: string;
  isDynamicNode: boolean;
  apy: string;
  total: string;
  acceptedCurrencies: Array<string>;
  totalNodes: number;
  availableNodes: number;
  purchaseMode: boolean;
  nodeAvailability: NodeAvailability;
  isLoadingFee: boolean;
  amount: string | '';
  requiresApproval?: boolean;
  approving: boolean;
  wallet?: ethers.providers.JsonRpcSigner;
  initiatedPayment: boolean;
  saleDisabled: boolean;
  generatingWhitelistProof: boolean;
  requiresWhitelisting: boolean;
  isWhitelisted: boolean;
  updatingData: boolean;
  tierMax: number;
  saleEnded: boolean;
};

const Header = memo(
  ({
    tierName,
    apy,
    total,
    isDynamicNode,
  }: {
    tierName: string;
    apy: string;
    total: string;
    isDynamicNode: boolean;
  }) => (
    <div>
      <div className="flex items-center justify-between">
        <p className="text-meldwhite">{tierName}</p>
        <img
          className="h-5 w-5 rounded-full bg-meldwhite drop-shadow-[0px_0px_4px_rgba(0,0,0,0.40)]"
          src={MeldLogoWhite}
          alt="MELD logo"
        />
      </div>
      <div className="mt-[10px] flex justify-between text-[14px]">
        <div className="flex gap-1">
          <p className="text-meldgray2">{isDynamicNode ? '' : 'REAL '} APY</p>
          <p className="text-meldwhite">{apy}%</p>
        </div>
        <div className="flex gap-1">
          <p className="text-meldgray2">RETURN</p>
          <p className="text-meldwhite">{total}%</p>
        </div>
      </div>
    </div>
  ),
);

const Price = memo(
  ({
    price,
    acceptedCurrencies,
    isDynamic,
  }: {
    price: string;
    acceptedCurrencies: Array<string>;
    isDynamic: boolean;
  }) => (
    <div className="mt-[45px] flex flex-col items-center justify-center">
      <p className="font-bold text-[56px] text-meldwhite">{price}</p>
      <p className="-mt-[2px] text-[14px] uppercase text-meldwhite">{acceptedCurrencies.join('/')}</p>
      <p className="mt-[6px] flex items-center justify-center gap-1 text-[14px] text-meldwhite/50">
        / Per <img src={isDynamic ? NodeDynamic : Node} alt="MELD Node" className="h-5 w-5" /> zkBanking Node
      </p>
    </div>
  ),
);

const AvailabilityBar = memo(({ availableNodes, totalNodes }: { availableNodes: number; totalNodes: number }) => {
  return (
    <>
      <div className="relative mt-[71px] h-[9px] overflow-hidden rounded-lg bg-meldwhite/30">
        <div
          className="absolute left-0 top-0 h-full bg-meldgreen transition-all duration-1000 ease-linear"
          style={{ width: `${100 - (availableNodes * 100) / totalNodes}%` }}
        />
      </div>
      <div className="mt-[7px] flex items-end justify-between">
        <div className="flex items-end">
          <p className="relative top-[2px] font-bold text-[26px] text-meldwhite">{totalNodes - availableNodes}</p>
          <p className="text-[14px] text-meldgray2">/{totalNodes}</p>
        </div>
        <p className="text-[14px] text-meldgray2">100%</p>
      </div>
    </>
  );
});

const Selectors = memo(
  ({
    setAmount,
    amSelected,
    price,
    amount,
    disabled,
    tierMax,
    saleEnded,
  }: {
    amSelected: boolean;
    price: number;
    amount: string;
    setAmount: (amount: string) => void;
    disabled: boolean;
    tierMax: number;
    saleEnded: boolean;
  }) => {
    const { decimalSeparator, thousandsSeparator } = useStore((state) => state.numberFormatting, shallow);
    return (
      <div
        className={cn(
          'h-0 overflow-hidden transition-all duration-300 ',
          amSelected && '-mb-[30px] h-[222px] delay-300 duration-700 min-[600px]:h-[132px]',
          (disabled || saleEnded) && 'pointer-events-none opacity-80',
        )}
      >
        <TokenSelector
          thousandsSeparator={thousandsSeparator}
          tierMax={tierMax}
          key={decimalSeparator}
          decimalSeparator={decimalSeparator}
          price={price}
          amount={amount}
          setAmount={setAmount}
        />
      </div>
    );
  },
);

const getButtonData = ({
  nodeAvailability,
  requiresApproval,
  approving,
  initiatedPayment,
  generatingWhitelistProof,
  updatingData,
  saleEnded,
}: {
  nodeAvailability: NodeAvailability;
  requiresApproval?: boolean;
  approving: boolean;
  initiatedPayment: boolean;
  generatingWhitelistProof: boolean;
  updatingData: boolean;
  saleEnded: boolean;
}) => {
  if (saleEnded) {
    return {
      className: 'bg-meldgreen hover:bg-meldgreen/80 text-meldblack',
      text: 'sale ended',
    };
  }
  if (generatingWhitelistProof) {
    return {
      className: 'bg-meldgreen hover:bg-meldgreen/80 text-meldblack',
      text: 'generating whitelist proof',
    };
  }
  if (updatingData) {
    return {
      className: 'bg-meldgreen hover:bg-meldgreen/80 text-meldblack',
      text: 'updating data',
    };
  }
  if (requiresApproval && nodeAvailability !== NodeAvailability.SOLD_OUT) {
    return {
      className: 'bg-meldgreen hover:bg-meldgreen/80 text-meldblack',
      text: approving ? 'approving' : 'approve',
    };
  }
  switch (nodeAvailability) {
    case NodeAvailability.AVAILABLE:
      return {
        className: 'bg-meldgreen hover:bg-meldgreen/80 text-meldblack',
        text: initiatedPayment ? 'purchasing' : 'purchase',
      };
    case NodeAvailability.SOLD_OUT:
      return { className: 'bg-meldwhite/50 hover:bg-meldwhite/40 cursor-auto', text: 'sold out' };
    case NodeAvailability.WHITELIST:
      return { className: 'bg-meldwhite hover:bg-white/80 text-meldblack', text: 'white list' };
  }
};

const ActionButton = memo(
  ({
    nodeAvailability,
    onClick,
    isLoading,
    requiresApproval,
    approving,
    disabled,
    initiatedPayment,
    generatingWhitelistProof,
    updatingData,
    saleEnded,
  }: {
    nodeAvailability: NodeAvailability;
    selectedCard: boolean;
    onClick: () => void;
    isLoading: boolean;
    requiresApproval?: boolean;
    approving: boolean;
    disabled: boolean;
    initiatedPayment: boolean;
    generatingWhitelistProof: boolean;
    updatingData: boolean;
    saleEnded: boolean;
  }) => {
    const data = useMemo(() => {
      return {
        ...getButtonData({
          nodeAvailability,
          requiresApproval,
          approving,
          initiatedPayment,
          generatingWhitelistProof,
          updatingData,
          saleEnded,
        }),
      };
    }, [
      nodeAvailability,
      requiresApproval,
      approving,
      initiatedPayment,
      generatingWhitelistProof,
      updatingData,
      saleEnded,
    ]);

    return (
      <Button
        disabled={generatingWhitelistProof ? false : disabled}
        onClick={onClick}
        className={cn(
          'mt-[42px] h-[59px] w-full whitespace-nowrap rounded-lg font-semibold text-lg uppercase tracking-[0.5px] text-white transition-all duration-1000',
          data.className,
          disabled && !generatingWhitelistProof && 'opacity-50',
          isLoading && 'pointer-events-none animate-pulse',
        )}
      >
        {data.text}
      </Button>
    );
  },
);

const SuccessMessage = memo(
  ({
    amSelected,
    purchaseMode,
    unmount,
    blockExplorerUrl,
    amount,
    total,
    price,
    nodeType,
    isDynamicNode,
  }: {
    amSelected: boolean;
    purchaseMode: boolean;
    unmount: boolean;
    blockExplorerUrl: string;
    amount: string;
    total: string;
    price: string;
    nodeType: string;
    isDynamicNode: boolean;
  }) => {
    return (
      <div
        className={cn(
          'pointer-events-none absolute left-0 top-0 z-[100] w-0 -translate-y-[50px] opacity-0 transition-all delay-300 duration-500',
          amSelected && 'h-full w-full md:w-[630px]',
          amSelected && purchaseMode && 'pointer-events-auto translate-y-0 opacity-100',
          unmount && 'delay-0',
        )}
      >
        <p className="pt-[35px] font-bold text-[56px] text-meldgreen">Purchase Successful</p>
        <p className="mt-[5px] text-[22px] text-meldgreen">The zkBanking Node NFTs have been minted to your wallet.</p>
        <div className="mt-[50px] flex gap-4">
          <div className="flex flex-col gap-[32px] text-[20px] text-meldgray4">
            <p>Node type</p>
            <p>Quantity</p>
            <p>Price per node</p>
            <p>Total</p>
            <p>Network</p>
            <p>Transaction reciept</p>
          </div>
          <div className="flex flex-col gap-[32px] text-[20px] text-meldwhite">
            <p className="relative">
              <p className="absolute -top-[3px] flex items-start justify-center gap-2 !leading-[24px] md:items-center md:whitespace-nowrap">
                <img src={isDynamicNode ? NodeDynamic : Node} alt="MELD Node" className="h-[30px] w-[30px]" />{' '}
                {nodeType} zkBanking
              </p>
              <p className="invisible opacity-0">transparent</p>
            </p>
            <p>{amount}</p>
            <p>{price}</p>
            <p>{total}</p>
            <p className="relative">
              <p className="absolute -top-[4px] flex items-center gap-2">
                <TokenIcon
                  boxShadow={false}
                  mainWrapperClassName="h-[30px] w-[30px]"
                  innerWrapperClassName="h-[30px] w-[30px]"
                  imgSrc={networks?.[MELD_NETWORK]?.darkIcon ?? ''}
                />
                MELD
              </p>
              <p className="invisible opacity-0">transparent</p>
            </p>

            <p className="relative -top-[1px] flex items-center">
              Meldscan{' '}
              <img
                onClick={() => window.open(blockExplorerUrl, '_blank')}
                src={shareIcon}
                className="cursor-pointer hover:opacity-60"
              />
            </p>
          </div>
        </div>
      </div>
    );
  },
);

export const Stats = memo(
  ({
    containerRef,
    amSelected,
    setHovering,
    setNotHovering,
    setSelected,
    tierName,
    price,
    total,
    acceptedCurrencies,
    apy,
    availableNodes,
    approving,
    nodeAvailability,
    totalNodes,
    isDynamicNode,
    unmount,
    purchaseMode,
    handleActionButton,
    amount,
    setAmount,
    isLoadingFee,
    requiresApproval,
    initiatedPayment,
    notEnoughBalanceToPay,
    notEnoughNativeToken,
    saleDisabled,
    generatingWhitelistProof,
    style,
    updatingData,
    tierMax,
    saleEnded,
  }: Props & {
    isDynamicNode: boolean;
    amSelected: boolean;
    setHovering: () => void;
    setNotHovering: () => void;
    setSelected: () => void;
    selectedCard: SelectedCard | null;
    containerRef: RefObject<HTMLDivElement>;
    unmount: boolean;
    purchaseMode: boolean;
    handleActionButton: () => void;
    amount: string;
    setAmount: (amount: string) => void;
    notEnoughBalanceToPay: boolean;
    notEnoughNativeToken: boolean;
    style: {
      transform: string;
      scale: Interpolation<number, number>;
      rotateX: SpringValue<number>;
      rotateY: SpringValue<number>;
      rotateZ: SpringValue<number>;
    };
  }) => {
    const evmAddress = useStore((state) => state.evmData.evmAddress);

    const [enableError, setEnableError] = useState(false);

    useEffect(() => {
      const timeout = 0;

      if (amSelected) {
        setTimeout(() => setEnableError(true), 1000);
      } else {
        clearTimeout(timeout);
        setEnableError(false);
      }

      return () => clearTimeout(timeout);
    }, [amSelected]);

    return (
      <animated.div
        onMouseEnter={setHovering}
        onMouseLeave={setNotHovering}
        ref={containerRef}
        onClick={setSelected}
        className={cn('relative w-full', !amSelected && 'cursor-pointer')}
        style={!amSelected ? style : undefined}
      >
        <div
          className={cn(
            'relative box-border flex h-full w-full flex-col rounded-[18px] border-solid  border-meldpink px-5 pb-[21px] pt-[20.49px] transition-all duration-500',
            isDynamicNode && 'border-transparent bg-meldblack',
            amSelected && purchaseMode && 'pointer-events-none translate-y-[50px] opacity-0',
            unmount && 'delay-300',
          )}
        >
          <Header isDynamicNode={isDynamicNode} tierName={tierName} apy={apy} total={total} />
          <Price price={price} acceptedCurrencies={acceptedCurrencies} isDynamic={isDynamicNode} />
          <AvailabilityBar availableNodes={availableNodes} totalNodes={totalNodes} />
          <Selectors
            setAmount={setAmount}
            amSelected={amSelected}
            price={+price}
            amount={amount}
            disabled={initiatedPayment || approving}
            tierMax={tierMax}
            saleEnded={saleEnded}
          />
          <ActionButton
            isLoading={isLoadingFee || approving || updatingData || initiatedPayment || generatingWhitelistProof}
            onClick={handleActionButton}
            saleEnded={saleEnded}
            nodeAvailability={nodeAvailability}
            selectedCard={amSelected}
            requiresApproval={requiresApproval}
            approving={approving}
            initiatedPayment={initiatedPayment}
            generatingWhitelistProof={generatingWhitelistProof}
            disabled={
              amSelected &&
              ((+amount === 0 && !updatingData) ||
                !evmAddress ||
                notEnoughBalanceToPay ||
                notEnoughNativeToken ||
                saleDisabled ||
                saleEnded ||
                nodeAvailability === NodeAvailability.WHITELIST)
            }
            updatingData={updatingData}
          />
          {amSelected && <UnsupportedWalletMessage canShow={amSelected} enabled={amSelected && enableError} />}
        </div>
      </animated.div>
    );
  },
);

export const Card = memo(({ index, tierName, wallet, ...rest }: Props) => {
  // internal reference to animate out
  const [internalAmount, setInternalAmount] = useState('');
  const initiatedPayment = useStore((state) => state.data.initiatedPayment, shallow);
  const [hovering, setHovering] = useState(false);
  const [_purchaseMode, setPurchaseMode] = useState(false);
  const [unmount, setUnmount] = useState(false);

  const setSelectedCard = useStore((state) => state.setSelectedCard);
  const setEvmData = useStore((state) => state.setEvmData);
  const setData = useStore((state) => state.setData);

  const setInputData = useStore((state) => state.setInputData);
  const [blockExplorerUrl, setBlockExplorerUrl] = useState('');
  const balanceAmount = useStore((state) => state.selectedWalletToken?.amount, shallow);
  const fiatAmount = useStore((state) => state.inputData.fiatAmount);
  const inputError = useStore((state) => state.inputData.inputError);

  const { containerRef, amSelected, selectedCard, windowSize, dimensionsRef, handleDimensions } = useDimensions({
    index,
  });
  const { hasBannerAndIsMobile } = useHasBanner();

  const style = use3d(containerRef);
  const notEnoughBalanceToPay = useMemo(() => +(balanceAmount ?? 0) < +fiatAmount, [balanceAmount, fiatAmount]);
  const notEnoughNativeToken = useMemo(() => inputError === ValueInputError.NOT_ENOUGH_NATIVE_TOKEN, [inputError]);

  useEffect(() => {
    if (amSelected) {
      setInternalAmount(rest.amount);
    }
  }, [amSelected, rest.amount]);

  useEffect(() => {
    if (amSelected && rest.nodeAvailability !== NodeAvailability.SOLD_OUT) {
      // hardcode to 1
      setInputData({ amount: '1', fiatAmount: (1 * +rest.price).toString() });
    } else if (amSelected) {
      setInputData({ amount: '', fiatAmount: '' });
    }
  }, [setInputData, rest.price, amSelected, rest.nodeAvailability]);

  const handleUnselect = useCallback(() => {
    // do not allow user to go back if they initiated payment or are approving
    if (initiatedPayment || rest.approving) return;
    // after purchase
    if (_purchaseMode) {
      // set this to user different timings when animating the success UI out
      setUnmount(true);
      // hide success UI
      setPurchaseMode(false);
      // mutate the data we have stored to show an increase to the end user
      setTimeout(() => {
        queryClient.setQueryData([GET_SALE_DATA_QUERY_KEY], (data: Array<Sale> | undefined) => {
          return data?.map((a, i) => {
            return i === index
              ? {
                  ...a,
                  totalMinted: (+a.totalMinted + +rest.amount).toString(),
                  tierMax: a.tierMax - +rest.amount,
                }
              : i === 0
                ? {
                    ...a,
                    tierMax: a.tierMax - +rest.amount, // deduct also from dynamic tier
                  }
                : a;
          });
        });
        // refetch all data temporarily until we figure the logic to mutate the correct data. Whats above works well for the tier the user is seeing.
        setTimeout(() => {
          queryClient.invalidateQueries([GET_SALE_DATA_QUERY_KEY]);
        }, 1000);
      }, 750);

      // reset form and invalidate the calculate fee query
      setTimeout(() => {
        setInputData({
          amount: rest.availableNodes - +rest.amount <= 0 ? '' : '1',
          fiatAmount: rest.availableNodes - +rest.amount <= 0 ? '' : (1 * +rest.price).toString(),
        });
        setEvmData({ evmRequiresApproval: undefined });
        queryClient.invalidateQueries([CALCULATE_FEE_QUERY_KEY]);
      }, 500);

      // reset this so the user can come back to the same card and go through the same flow as before
      setTimeout(() => {
        setUnmount(false);
      }, 1000);

      return;
    }

    setSelectedCard(null);
    setEvmData({ evmRequiresApproval: false });
  }, [
    setSelectedCard,
    initiatedPayment,
    _purchaseMode,
    index,
    rest.amount,
    setEvmData,
    setInputData,
    rest.approving,
    rest.price,
    rest.availableNodes,
  ]);

  const handleActionButton = useCallback(async () => {
    if (rest.isLoadingFee || rest.requiresApproval === undefined) return;
    if (amSelected) {
      // approve
      if (rest.requiresApproval) {
        try {
          setData({ approving: true });
          await payEvm({
            wallet: wallet as ethers.providers.JsonRpcSigner,
            estimateCost: false,
            onCompleteApproval: () => {
              capture(MetricEvents.UserApprovesToken, {
                tier: selectedCard?.index,
                quantity: rest.amount,
                fiatAmount,
                token: useStore.getState().selectedWalletToken?.symbol ?? 'Unknown',
              });
              setEvmData({ evmRequiresApproval: false });
            },
          });
          queryClient.invalidateQueries([GET_METAMASK_WALLET_TOKENS_QUERY_KEY]);
        } catch (err) {
          if (
            (err as Error)?.message.includes('TOTAL_SUPPLY_CAP_REACHED') ||
            (err as Error)?.message.includes('TIER_SUPPLY_CAP_REACHED')
          ) {
            queryClient.invalidateQueries([GET_SALE_DATA_QUERY_KEY]);
            setInputData({ inputError: ValueInputError.AVAILABLE_AMOUNT_CHANGED, amount: '', fiatAmount: '' });
            setTimeout(() => {
              setInputData({ inputError: null });
            }, 5000);
          } else if (!(err as Error)?.message?.includes('User declined to sign the transaction')) {
            captureError(err);
          }
        }
        setData({ approving: false });
      }
      // pay
      else if (rest.requiresApproval === false) {
        setData({ initiatedPayment: true });
        try {
          const { hash } = await payEvm({
            wallet: wallet as ethers.providers.JsonRpcSigner,
            estimateCost: false,
            onAcceptedPayment: () => {
              capture(MetricEvents.UserPurchases, {
                tier: selectedCard?.index,
                quantity: rest.amount,
                fiatAmount,
                token: useStore.getState().selectedWalletToken?.symbol ?? 'Unknown',
              });
            },
          });
          // only show success UI if we hash a hash, due to race conditions the node may think the user still needs to approve
          if (hash) {
            // do this after so the button doesn't stop loading while animating to the Success UI
            setTimeout(() => {
              setData({ initiatedPayment: false });
              queryClient.invalidateQueries([GET_METAMASK_WALLET_TOKENS_QUERY_KEY]);
            }, 500);

            setBlockExplorerUrl(convertEVMHashToLink(hash, MELD_NETWORK));
            // update purchase history
            queryClient.invalidateQueries([GET_PURCHASES]);
          }
          // if the node thinks the user has to approve, run the calculate fee query again
          else {
            setEvmData({ evmRequiresApproval: undefined });
            setData({ initiatedPayment: false });
            queryClient.invalidateQueries([CALCULATE_FEE_QUERY_KEY]);
            return;
          }
        } catch (err) {
          console.log(err);
          setData({ initiatedPayment: false });
          if (
            (err as Error)?.message.includes('TOTAL_SUPPLY_CAP_REACHED') ||
            (err as Error)?.message.includes('TIER_SUPPLY_CAP_REACHED')
          ) {
            queryClient.invalidateQueries([GET_SALE_DATA_QUERY_KEY]);
            setInputData({ inputError: ValueInputError.AVAILABLE_AMOUNT_CHANGED, amount: '', fiatAmount: '' });
            setTimeout(() => {
              setInputData({ inputError: null });
            }, 5000);
          } else if (!(err as Error)?.message?.includes('User declined to sign the transaction')) {
            captureError(err);
          }
          return;
        }

        setPurchaseMode(true);
      }
    }
  }, [
    amSelected,
    rest.requiresApproval,
    wallet,
    setEvmData,
    setData,
    rest.isLoadingFee,
    setInputData,
    selectedCard?.index,
    rest.amount,
    fiatAmount,
  ]);

  const handleSetSelected = useCallback(() => {
    if (selectedCard?.index === index) return;
    capture(index === 0 ? MetricEvents.UserClicksDynamicNodeCard : MetricEvents.UserClicksStaticNodeCard);
    handleDimensions();
    setInputData({ amount: '', fiatAmount: '', inputError: null });
    setData({ notEnoughToken: false });
    setSelectedCard({ index, requiresWhitelisting: rest.requiresWhitelisting, availability: rest.nodeAvailability });
  }, [index, setSelectedCard, setInputData, selectedCard, setData, rest, handleDimensions]);

  const handleSetHovering = useCallback(() => setHovering(true), [setHovering]);

  const handleSetNotHovering = useCallback(() => setHovering(false), [setHovering]);

  const handleSetAmount = useCallback(
    (amount: string) => {
      if (amount === '') {
        setEvmData({ evmRequiresApproval: false });
      }
      setInputData({ amount, fiatAmount: (+amount * +rest.price).toString() });
    },
    [setInputData, rest.price, setEvmData],
  );

  const { isMobile } = useWindowSize();

  const maxWidth = useMemo(() => (windowSize.x < 768 ? windowSize.x - 48 : 630), [windowSize]);

  // this affects where the card animates to (height) once selected
  const heightValue = useMemo(
    () => (windowSize.x < 439 && index !== 0 ? 294 : isMobile ? (index === 0 ? 275 : 271) : 308),
    [windowSize, index, isMobile],
  );

  return (
    <>
      {amSelected && !isMobile && (
        <div onClick={handleUnselect} className="fixed left-0 top-0 z-50 h-screen w-screen" />
      )}
      {amSelected && isMobile && (
        <div
          onClick={handleUnselect}
          className="fixed bottom-0 left-[50%] z-[1000] mx-auto flex -translate-x-1/2 justify-center py-2  text-base uppercase text-meldblack"
        >
          <div className="flex h-10 w-[100px] items-center justify-center rounded-full bg-white text-center font-bold drop-shadow-lg backdrop-blur-sm">
            Back
          </div>
        </div>
      )}
      <div
        className={cn(
          'relative left-0 top-0 z-10 w-full transition-all duration-500 will-change-auto',
          amSelected && 'z-[100] w-full',
          hovering && 'will-change-[left,top,width]',
          selectedCard !== null && index !== selectedCard.index && 'pointer-events-none z-0 scale-[60%] opacity-0',
        )}
        style={
          amSelected
            ? {
                top: pxToRem(
                  index === 0 && isMobile && document.getElementById('layout')?.scrollTop === 0
                    ? 0
                    : dimensionsRef.current.top > heightValue
                      ? -1 * (dimensionsRef.current.top - heightValue) + (hasBannerAndIsMobile ? 40 : 0)
                      : heightValue - dimensionsRef.current.top + (hasBannerAndIsMobile ? 40 : 0),
                ),
                left: pxToRem(isMobile ? 0 : (windowSize.x - maxWidth) / 2 - dimensionsRef.current.left),
                width: pxToRem(maxWidth),
              }
            : undefined
        }
      >
        {amSelected && (
          <SuccessMessage
            unmount={unmount}
            purchaseMode={_purchaseMode}
            amSelected={amSelected}
            blockExplorerUrl={blockExplorerUrl}
            price={formatCurrency(Number(rest.price))}
            total={formatCurrency(+rest.price * +rest.amount)}
            amount={rest.amount}
            nodeType={index === 0 ? 'Dynamic' : 'Static'}
            isDynamicNode={index === 0}
          />
        )}
        <Stats
          {...rest}
          tierName={tierName}
          amSelected={amSelected}
          selectedCard={selectedCard}
          setSelected={handleSetSelected}
          index={index}
          containerRef={containerRef}
          setHovering={handleSetHovering}
          setNotHovering={handleSetNotHovering}
          purchaseMode={_purchaseMode}
          unmount={unmount}
          handleActionButton={handleActionButton}
          setAmount={handleSetAmount}
          amount={internalAmount}
          notEnoughBalanceToPay={notEnoughBalanceToPay}
          notEnoughNativeToken={notEnoughNativeToken}
          style={style}
          isLoadingFee={rest.isLoadingFee || rest.requiresApproval === undefined}
        />
      </div>
    </>
  );
});
