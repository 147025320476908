import { memo, useRef } from 'react';
import { ConnectButtonMenu } from './connect-button/ConnectButtonMenu';
import { Logo } from './logo';
import { useStore } from '@store/store';
import { cn } from '@utils/cn';
import { shallow } from 'zustand/shallow';
import { useWindowSize } from '@hooks/use-window-size';
import { pxToRem } from '@utils/px-to-rem';
import { useHasBanner } from '@hooks/use-has-banner';

export const Header = memo(() => {
  const selectedCard = useStore((state) => state.selectedCard, shallow);
  const ref = useRef<HTMLDivElement>(null);

  const { isMobile } = useWindowSize();
  const { hasBannerAndIsMobile } = useHasBanner();

  const data = ref.current?.getBoundingClientRect?.();

  return (
    <div
      ref={ref}
      className={cn('relative top-0 z-[100] flex justify-between transition-all duration-500')}
      style={
        selectedCard
          ? {
              top: pxToRem((data?.top ?? 0) * -1 + (isMobile ? 24 : 50) + (hasBannerAndIsMobile ? 50 : 0)),
            }
          : undefined
      }
    >
      <Logo />
      <ConnectButtonMenu />
    </div>
  );
});
