import { useMemo } from 'react';

import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { MELD_NETWORK } from 'src/contants/meld';

export const useUnsupportedExtNetwork = () => {
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const networks = useStore((state) => state.networks, shallow);
  const chainId = useStore((state) => state.evmData.evmConnectedChainId);

  const { wrongEvmNetwork, correctChainId } = useMemo(() => {
    const correctChainId = networks?.[selectedWalletToken?.network ?? MELD_NETWORK]?.chainId;

    return {
      wrongEvmNetwork:
        selectedWalletToken &&
        selectedWalletToken.chainType === NetworkChainType.EVM &&
        Boolean(chainId && chainId !== correctChainId),
      correctChainId,
    };
  }, [selectedWalletToken, chainId, networks]);

  return {
    wrongEvmNetwork,
    correctChainId,
  };
};
