import evmContractUtils from './evm/evmContractUtils';
import MELDZkNodesNFTMinter from '../abi/MELDZkNodesNFTMinter.json';
import { ethers, Event } from 'ethers';
import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { convertEVMHashToLink } from './transaction-link.util';
import { isNotProdEnv } from './environments-helper';
import { CONTRACTS, MELD_NETWORK } from 'src/contants/meld';
import { WAGMI_CHAINS } from '@components/wagmi-provider/chains';

const INITIAL_BLOCK = isNotProdEnv ? 2149663 : 863907;
const MAX_REQUESTED_BLOCKS = 2000;

type EventWithTimestamp = Event & { timestamp: number };

export const getPurchases = async (
  address: string,
  availableTokens: AvailableToken[],
  startingBlock = INITIAL_BLOCK,
) => {
  const provider = new ethers.providers.JsonRpcProvider(WAGMI_CHAINS[0].rpcUrls.public.http[0]);
  const contract = await evmContractUtils.connect(provider, CONTRACTS.ZkNodesNFTMinter, MELDZkNodesNFTMinter);

  const blockNumber = await provider.getBlockNumber();

  if (startingBlock > blockNumber) {
    return { data: [], blockNumber: blockNumber };
  }

  let blocksToLookFor = startingBlock < blockNumber ? blockNumber - startingBlock : 1;
  let initialBlock = startingBlock;
  let finalBlock = startingBlock + (blocksToLookFor >= MAX_REQUESTED_BLOCKS ? MAX_REQUESTED_BLOCKS : blocksToLookFor);
  let allEvents: Event[] = [];
  while (blocksToLookFor) {
    const events = await contract.queryFilter(
      'ZkNodesPurchased',
      initialBlock,
      // covers use-case of looking for just 1 block, the final one
      finalBlock > blockNumber ? blockNumber : finalBlock,
    );
    allEvents = allEvents.concat(events);
    blocksToLookFor -= finalBlock - initialBlock;
    initialBlock = finalBlock;
    finalBlock += blocksToLookFor >= MAX_REQUESTED_BLOCKS ? MAX_REQUESTED_BLOCKS : blocksToLookFor;
  }

  for (let i = 0; i < allEvents.length; i++) {
    const block = await allEvents[i].getBlock();
    // @ts-expect-error 2353
    allEvents[i] = { ...allEvents[i], timestamp: block.timestamp };
  }

  return {
    data: (allEvents as Array<EventWithTimestamp>)
      .filter((a) => a.args?.[0] === address)
      .map((event) => {
        const tokenAddress = event.args?.tokenAddress?.toLowerCase();
        const amount = event.args?.amount.toString();
        const price = event.args?.price.toString();
        const tier = event.args?.tier.toString();
        const token = availableTokens.find((a) => a.contract === tokenAddress && a.network === MELD_NETWORK);

        return {
          symbol: token?.symbol,
          description: `${amount} ${+tier === 0 ? 'Dynamic' : 'Static'} ${+amount === 1 ? 'Node' : 'Nodes'}`,
          amount: Number(+amount * +price),
          timestamp: event.timestamp,
          explorerUrl: convertEVMHashToLink(event.transactionHash, MELD_NETWORK),
        };
      })
      .sort((a, b) => b.timestamp - a.timestamp),
    blockNumber: finalBlock,
  };
};
