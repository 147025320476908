import { useStore } from '@store/store';
import { useMemo } from 'react';
import { useWindowSize } from './use-window-size';

export const useHasBanner = () => {
  const { isMobile } = useWindowSize();
  const acceptedTerms = useStore((state) => state.data.acceptedTerms);
  const saleStopped = useStore((state) => state.data.saleStopped);
  const mismatchingRootNode = useStore((state) => state.data.mismatchingRootNode);

  const hasBanner = useMemo(
    () => (saleStopped || mismatchingRootNode) && acceptedTerms,
    [saleStopped, mismatchingRootNode, acceptedTerms],
  );

  return { hasBanner, hasBannerAndIsMobile: isMobile && hasBanner };
};
